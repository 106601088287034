import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum OnboardingGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
    Question5VerticalButtons = "Question5VerticalButtons",
    Question6VerticalButtons = "Question6VerticalButtons",
    Question7VerticalButtons = "Question7VerticalButtons",
    Question8VerticalButtons = "Question8VerticalButtons",
    Question9VerticalButtons = "Question9VerticalButtons",
}

export type OnboardingGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useOnboardingGuideDialog = (mode: "tutorial" | "look up", onConfirm: FormProps<OnboardingGuideForm>["onSubmit"]) => {
    const form: OnboardingGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        if ( mode === "tutorial" ) {
            questionPages.push({
                type: "page",
                title: "Sådan finder du vores guides",
                item: {
                    type: "group",
                    render: "all at once",
                    items: [
                        {
                            type: "info",
                            render: "default",
                            text: `
                                Vi har lavet en række guides, der forklarer konceptet og hvordan du bruger systemet.<br />
                                <br />
                                Som en del af din onboarding vil du få vist de relevante guides, efterhånden som du støder på noget nyt på platformen.<br />
                                <br />
                                Du kan finde disse guides og andre under menupunktet “Guides” i topbaren.
                            `,
                        },
                        {
                            id: OnboardingGuideFormIds.Question1VerticalButtons,
                            type: "vertical-buttons",
                            mode: "regular",
                            label: "Hvordan finder jeg jeres guides frem?",
                            answerTexts: [
                                "I sidebaren",
                                "På min profil",
                                "I topbaren"
                            ],
                            selectedChoiceIndex: null,
                            dirty: false,
                            validIndexes: [2],
                        },
                    ],
                },
            });
        }

        questionPages.push({
            type: "page",
            title: "Sådan fungerer konceptet",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Du er selvstændig havemand på platformen. Vi er din samarbejdspartner og du kan altid bede os om hjælp.<br />
                            <br />
                            Vi finder kunder i nærheden af dig og tilbyder de relevante opgaver til dig. Vi håndterer fakturering på dine vegne og står for opkrævning af betaling.<br />
                            <br />
                            Hvis du påtager dig en opgave, er det dit ansvar at sørge for, at opgaven bliver løst og at kunden får en god oplevelse.
                        `,
                    },
                    {
                        id: OnboardingGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan fungerer samarbejdet?",
                        answerTexts: [
                            "Jeg er ansat ved Go Go Garden",
                            "Jeg er selvstændig og Go Go Garden er min samarbejdspartner",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan tjener du penge",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Der er forskel på din timesats og kundens timepris. Du kender dine egne timesatser, men du bør også orientere dig om kundernes timepriser, så du kan rådgive dem om pris. Du kan finde priserne på hjemmesiden.<br />
                            <br />
                            Kunderne betaler altid kun for effektiv arbejdstid – dvs. ikke transport til og fra opgaven. Hvis du skal køre haveaffald bort, er kørslen til nærmeste genbrugsplads selvfølgelig en del af arbejdstiden.<br />
                            <br />
                            Når du har løst opgaven, registrerer du tid og kunden bliver automatisk faktureret på dine vegne. Når kunden har betalt, kan du anmode om at få udbetalt dit honorar fra din profil.
                        `,
                    },
                    {
                        id: OnboardingGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad kan jeg registrere tid for?",
                        answerTexts: [
                            "Dialog med kunden, kørsel og effektiv arbejdstid",
                            "Min effektive arbejdstid"
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan beder du om hjælp",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Hvis du bliver i tvivl om noget og ikke kan finde svaret i vores guides, kan du altid bede os om hjælp.<br />
                            <br />
                            Du kan kontakte os via knappen “Bed om hjælp”, som du finder i topbaren, så svarer vi dig hurtigst muligt.
                        `,
                    },
                    {
                        id: OnboardingGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan kan jeg bede om hjælp?",
                        answerTexts: [
                            "Ved at ringe til Go Go Garden",
                            "Ved at bruge knappen “Bed om hjælp”",
                            "Ved at sende en sms til Go Go Garden",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan bliver du tilbudt nye opgaver",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du bliver tilbudt en opgave, vil du modtage både en email og en sms, som indeholder et link til en oversigt over dine opgaver.
                        `,
                    },
                    {
                        id: OnboardingGuideFormIds.Question5VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan modtager jeg nye opgaver?",
                        answerTexts: [
                            "På email og sms",
                            "Jeg bliver ringet op",
                            "Jeg er nødt til at holde øje med min oversigt",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan holder du styr på dine opgaver",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når der er aktuelle opgaver, vil de fremgå af din “Oversigt”. Det kan f.eks. være, når du tilbydes en ny opgave.<br />
                            <br />
                            Du bør altid besvare opgaver inden for svarfristen og du bliver mindet om dine opgaver, så du behøver ikke selv holde øje.
                        `,
                    },
                    {
                        id: OnboardingGuideFormIds.Question6VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan holder jeg styr på mine opgaver?",
                        answerTexts: [
                            "Jeg besvarer opgaverne på min “Oversigt”",
                            "Det står jeg selv for",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Svarfrister for nye opgaver",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Du har i alt 24 timer til at besvare en ny opgave. Dog tilbydes opgaven videre til en anden havemand efter 4 timer, så de får mulighed for at besvare den samtidig med dig.<br />
                            <br />
                            Hvis en anden havemand siger “ja tak” til opgaven før dig, bliver opgaven fjernet fra din oversigt.<br />
                            <br />
                            Opgaver kan desuden være tilbudt til andre havemænd før dig, så svar hurtigst muligt, hvis du gerne vil have opgaven.
                        `,
                    },
                    {
                        id: OnboardingGuideFormIds.Question7VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvor hurtigt bør jeg besvare en ny opgave?",
                        answerTexts: [
                            "Hurtigst muligt",
                            "Inden for 4 timer",
                            "Inden for 24 timer",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan indstiller du hvilke nye opgaver du tilbydes",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            På din profil kan du se og ændre dine indstillinger, herunder:
                            <ul>
                                <li>Din adresse og hvor langt du vil køre for opgaver</li>
                                <li>Hvilke opgaver du kan udføre</li>
                                <li>Hvilke redskaber du kan medbringe</li>
                            </ul>
                            <br />
                            Du vil kun blive tilbudt opgaver, der matcher dine indstillinger. Får du f.eks. nye redskaber, er det derfor en god ide at holde din profil opdateret.
                        `,
                    },
                    {
                        id: OnboardingGuideFormIds.Question8VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan indstiller jeg hvilke nye opgaver jeg tilbydes?",
                        answerTexts: [
                            "Ved at kontakte Go Go Garden",
                            "På min profil",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan indstiller du hvornår du tilbydes nye opgaver",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Du har mulighed for at sætte dig selv på pause, hvis du gerne vil undgå at blive tilbudt nye opgaver i en periode. Det kunne f.eks. være i din ferie, ved sygdom eller hvis du bare ikke har lyst.<br />
                            <br />
                            Pause har kun en indflydelse på, om du tilbydes nye opgaver. Du forventes altså stadig at håndtere igangværende opgaver.<br />
                            <br />
                            Du kan altid gøre dig aktiv igen, når du er klar til at modtage flere opgaver.
                        `,
                    },
                    {
                        id: OnboardingGuideFormIds.Question9VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad gør jeg, hvis jeg ikke ønsker flere nye opgaver?",
                        answerTexts: [
                            "Jeg sætter mig selv på pause",
                            "Jeg undlader at besvare opgaver",
                            "Jeg kontakter Go Go Garden",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide og er nu klædt på ift. konceptet og hvordan du bliver tilbudt nye opgaver.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, [mode]);

    return useFormDialog(form, onConfirm);
};
