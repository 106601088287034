import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum RegistrationTaskGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
    Question5VerticalButtons = "Question5VerticalButtons",
    Question6VerticalButtons = "Question6VerticalButtons",
    Question7VerticalButtons = "Question7VerticalButtons",
}

export type RegistrationTaskGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useRegistrationTaskGuideDialog = (onConfirm: FormProps<RegistrationTaskGuideForm>["onSubmit"]) => {
    const form: RegistrationTaskGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        questionPages.push({
            type: "page",
            title: "Hvad går “Hvordan gik dit besøg?” ud på?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            “Hvordan gik dit besøg?”-opgaverne minder dig om, at du skal besøge kunden på en bestemt dato. Du kan finde dem under aktuelle eller fremtidige opgaver.<br />
                            <br />
                            Når du har besøgt kunden, forventes det, at du besvarer opgaven samme dag. Du kan se svarfristen på opgaven. Svarfristen er samme dato, som I har aftalt.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Hvis du ikke besvarer en opgave til tiden, vil du automatisk blive sat på pause, indtil du besvarer den.
                        `,
                    },
                    {
                        id: RegistrationTaskGuideFormIds.Question1VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvornår skal jeg besvare opgaven?",
                        answerTexts: [
                            "Inden for svarfristen, som jeg kan se på opgaven",
                            "Inden for en uge",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Kommunikation med kunden",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du har lavet en aftale med en kunde, er det vigtigt, at du overholder den, da kunden forventer, du kommer til aftalt tid.<br />
                            <br />
                            Hvis du bliver forhindret, bør du informere kunden om det i så god tid som muligt.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Kunden får det ikke at vide, hvis du ændrer datoen for besøget i systemet. Husk derfor selv at informere kunden.
                        `
                    },
                    {
                        id: RegistrationTaskGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Skal jeg selv informere kunden, hvis jeg ændrer vores aftale?",
                        answerTexts: [
                            "Ja",
                            "Nej, det sker automatisk",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, når du har løst opgaven?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du har besøgt kunden og har løst opgaven, så mangler du kun at registrere tid.<br />
                            <br />
                            Det gør du ved at besvare opgaven og bruge knappen “Jeg har besøgt kunden” og svare “ja” til “Har du udført havearbejde?”
                        `,
                    },
                    {
                        id: RegistrationTaskGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, når du har løst opgaven?",
                        answerTexts: [
                            "Jeg har besøgt kunden",
                            "Vi har ændret datoen for besøget",
                            "Vi har aflyst aftalen",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, når du kun har foretaget en besigtigelse?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Har du kun udført en besigtigelse, så besvarer du opgaven og bruger knappen “Jeg har besøgt kunden” og svarer “nej” til “Har du udført havearbejde?”.<br />
                            <br />
                            I det tilfælde forventes det, at I planlægger et nyt besøg for at løse opgaven. Har I allerede planlagt en ny dato, kan du registrere det med det samme.
                        `,
                    },
                    {
                        id: RegistrationTaskGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, når du foretaget en besigtigelse?",
                        answerTexts: [
                            "Jeg har besøgt kunden",
                            "Vi har ændret datoen for besøget",
                            "Vi har aflyst aftalen",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, hvis I laver datoen om?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Nogle gange får I behov for at ændre datoen for besøget. I det tilfælde besvarer du bare opgaven og bruger knappen “Vi har ændret datoen for besøget”.<br />
                            <br />
                            Det er vigtigt at sætte datoen for besøget rigtigt, da du først kan registrere tid for besøget på den valgte dato. Det gælder både, hvis I udskyder aftalen og hvis I rykker jeres aftale frem.
                        `,
                    },
                    {
                        id: RegistrationTaskGuideFormIds.Question5VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, hvis I laver datoen om?",
                        answerTexts: [
                            "Jeg har besøgt kunden",
                            "Vi har ændret datoen for besøget",
                            "Vi har aflyst aftalen",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, hvis I aflyser besøget?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Får I behov for at aflyse besøget, besvarer du opgaven og bruger knappen “Vi har aflyst aftalen”.<br />
                            <br />
                            I det tilfælde forventes det, at I planlægger et nyt besøg for at løse opgaven.
                        `,
                    },
                    {
                        id: RegistrationTaskGuideFormIds.Question6VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, hvis I aflyser besøget?",
                        answerTexts: [
                            "Jeg har besøgt kunden",
                            "Vi har ændret datoen for besøget",
                            "Vi har aflyst aftalen",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [2],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, hvis opgaven ikke bliver til noget?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Det kan ske, at kunden meddeler dig, at de ikke har brug for hjælp længere. Det kan også være, at du tager ud til kunden og først der finder ud af, at du ikke kan løse opgaven alligevel.<br />
                            <br />
                            I det tilfælde bruger du knappen “Opgaven skal lukkes”. Når du bliver spurgt, om vi skal finde en anden havemand til opgaven, svarer du ja, hvis du tror, kunden stadig ønsker hjælp.
                        `,
                    },
                    {
                        id: RegistrationTaskGuideFormIds.Question7VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, hvis opgaven ikke bliver til noget?",
                        answerTexts: [
                            "Jeg har besøgt kunden",
                            "Vi har ændret datoen for besøget",
                            "Vi har aflyst aftalen",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [3],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide og er nu klædt på til at besvare “Hvordan gik dit besøg?”-opgaver.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
