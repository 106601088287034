import { useGardenerBalance } from "../gardener/payout/gardener-balance.hook";
import { useGardenerPayout } from "../gardener/payout/gardener-payout.hook";
import { useRequestPayoutAction } from "../gardener/payout/request-payout.hook";
import { usePersonalInformation } from "../gardener/personal-information.hook";
import { ProfilePage } from "../gardener/ProfilePage";
import { useTaskResponseAction } from "../task/task-response-action.hook";
import { useUser } from "../user/UserProvider";
import { LinearProgress } from "./LinearProgress";

export const IntegratedProfilePage = () => {
    const gardener = useUser();
    const taskResponseAction = useTaskResponseAction();
    const requestPayoutAction = useRequestPayoutAction();

    const gardenerBalance = useGardenerBalance(gardener.id);
    const gardenerPayout = useGardenerPayout(gardener.id);
    const personalInformation = usePersonalInformation(gardener.id);

    if ( gardenerBalance === undefined || gardenerPayout === undefined || personalInformation === undefined ) return <LinearProgress />;

    return (
        <ProfilePage
            gardener={gardener}
            gardenerBalance={gardenerBalance}
            gardenerPayout={gardenerPayout}
            personalInformation={personalInformation}
            taskResponseAction={taskResponseAction}
            requestPayoutAction={requestPayoutAction}
        />
    );
};
