import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum ComplaintGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
    Question5VerticalButtons = "Question5VerticalButtons",
    Question6VerticalButtons = "Question6VerticalButtons",
}

export type ComplaintGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useComplaintGuideDialog = (onConfirm: FormProps<ComplaintGuideForm>["onSubmit"]) => {
    const form: ComplaintGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        questionPages.push({
            type: "page",
            title: "Hvad er en reklamation?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Hvis din kunde ikke er tilfreds med den ydelse, du har leveret, kan de vælge at reklamere over fakturaen.<br />
                            <br />
                            Det kan f.eks. ske, hvis de ikke mener, at arbejdet er udført godt nok eller fordi prisen ikke stemmer overens med hvad de var blevet oplyst om på forhånd.<br />
                            <br />
                            Det er ikke verdens undergang, når der opstår en reklamation, men det er selvfølgelig ærgerligt at skuffe kunden, særligt hvis man kunne have undgået det.
                        `,
                    },
                    {
                        id: ComplaintGuideFormIds.Question1VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvorfor reklamerer kunden?",
                        answerTexts: [
                            "Hvis de ikke har råd til at betale",
                            "Hvis de er utilfredse med prisen eller ydelsen",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad sker der, når du får en reklamation?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når kunden opretter en reklamation, tilsvarer det, at der gøres indsigelse imod din faktura.<br />
                            <br />
                            Vi opkræver ikke betaling, mens reklamationen står på, da det ikke længere er lovligt at pålægge rykkergebyrer og heller ikke er muligt at føre en udenretslig inkasso-proces.<br />
                            <br />
                            Du bør være opmærksom på, at du først modtager dit honorar, når kunden har betalt sin faktura. Det er derfor både i din og kundens interesse at løse reklamationen hurtigst muligt.
                        `,
                    },
                    {
                        id: ComplaintGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad medfører en reklamation ift. opkrævning af betaling?",
                        answerTexts: [
                            "Go Go Garden kan ikke opkræve betaling, mens reklamationen står på",
                            "Det har ingen indflydelse på opkrævning af betaling",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan hjælper Go Go Garden",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Hvis der opstår en reklamation, gør vi hvad vi kan for at gøre det så nemt for både dig og kunden som muligt.<br />
                            <br />
                            Først vil vi tale med kunden for at forstå hvad de er utilfredse med, og hvilken løsning de ønsker. Herefter vil vi tale med dig og høre hvad du synes om kundens løsningsforslag.<br />
                            <br />
                            Finder vi frem til en løsning i samtalen med dig, vil vi tilbyde denne til kunden. Hvis de accepterer den, fortæller vi det til dig og sørger for at rette fakturaen, hvis der er behov for det.<br />
                            <br />
                            De fleste reklamationer bliver hurtigt løst på denne måde, og du slipper for selv at have en svær samtale med kunden.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Hvis du løser reklamationen hurtigt, kan du ofte ændre kundens negative oplevelse til en positiv oplevelse.
                        `,
                    },
                    {
                        id: ComplaintGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Får jeg hjælp til at håndtere mine reklamationer?",
                        answerTexts: [
                            "Ja, Go Go Garden hjælper mig",
                            "Nej, jeg står helt selv for det",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan kan du løse reklamationer",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Det er op til dig, hvordan du vil løse en reklamation. Det er dit ansvar at håndtere den og forsøge at finde en løsning med kunden, men vi vil gøre vores bedste for at hjælpe dig.<br />
                            <br />
                            Du kan f.eks. tilbyde kunden at udbedre eventuelle mangler, at give kunden rabat eller i slemme tilfælde helt at droppe dit krav mod kunden ved at annullere fakturaen.<br />
                            <br />
                            Hvis du kommer til at ødelægge noget hos kunden ved et uheld, kan det være nødvendigt at erstatte det. Vi anbefaler derfor, at du har en forsikring, der kan hjælpe dig i det tilfælde.
                        `,
                    },
                    {
                        id: ComplaintGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvis ansvar er det at håndtere reklamationer?",
                        answerTexts: [
                            "Det er Go Go Gardens ansvar",
                            "Det er mit ansvar",
                            "Det er kundens ansvar",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad sker der, hvis der ikke umiddelbart kan findes en løsning?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Kan der ikke umiddelbart findes en løsning, når vi taler med dig, bliver du nødt til selv at række ud til kunden og forsøge at finde en løsning i al mindelighed.<br />
                            <br />
                            Når du har fundet en løsning, som I begge kan acceptere, så informerer du os blot om det, og så retter vi fakturaen, hvis der er behov for det. Herefter kan betalingsopkrævning fortsætte.<br />
                            <br />
                            Kan det slet ikke lade sig gøre for jer at blive enige om en løsning, kan du sige det til os, og så vil vi undersøge, hvad der kan gøres. Det vil variere fra sag til sag, hvad der kan betale sig.
                        `,
                    },
                    {
                        id: ComplaintGuideFormIds.Question5VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad sker der, hvis der ikke umiddelbart kan findes en løsning?",
                        answerTexts: [
                            "Så bliver jeg nødt til selv at række ud til kunden",
                            "Så sendes kunden til inkasso",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad forventer vi af dig?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Vi samarbejder med mange havemænd, som aldrig har modtaget en reklamation, men vi har fuld forståelse for, at der kan opstå misforståelser eller ske uheld.<br />
                            <br />
                            Får du en reklamation, forventer vi blot, at du håndterer det professionelt  og vi anbefaler, at du overvejer, hvordan du kan undgå at få en tilsvarende reklamation i fremtiden.
                        `,
                    },
                    {
                        id: ComplaintGuideFormIds.Question6VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan forventes der af mig, når jeg får en reklamation?",
                        answerTexts: [
                            "At jeg accepterer kundens løsningsforslag",
                            "At jeg håndterer reklamationen professionelt",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide og er nu klædt på ift. reklamationer.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
