import { GuidesPage } from "./GuidesPage";
import { useUser } from "../user/UserProvider";
import { useTaskResponseAction } from "../task/task-response-action.hook";

export const IntegratedGuidesPage = () => {
    const gardener = useUser();
    const taskResponseAction = useTaskResponseAction();

    return (
        <GuidesPage
            gardener={gardener}
            taskResponseAction={taskResponseAction}
        />
    );
};
