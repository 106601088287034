import { Card, CardActions, PositiveAction } from "../components/card";
import { PropsWithChildren } from "react";
import { AlternativeCardHeader } from "../components/card/AlternativeCardHeader";
import { AlternativeCardContent } from "../components/card/AlternativeCardContent";

interface GuideCardProps {
    title: string;
    startGuide: () => void;
}

export const GuideCard = (props: PropsWithChildren<GuideCardProps>) => {
    const { title, startGuide, children } = props;

    return (
        <Card>

            <AlternativeCardHeader title={title} />

            <AlternativeCardContent>
                {children}
            </AlternativeCardContent>

            <CardActions>
                <PositiveAction onClick={startGuide}>Start guide</PositiveAction>
            </CardActions>

        </Card>
    );
};
