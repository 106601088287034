import { useCallback } from "react";

import { Container } from "@material-ui/core";

import { ContentSection } from "../../application/ContentSection";
import { GreenButton, VerticalButtons } from "../../components/new-button";
import { Section } from "../../components/section/Section";
import { useTranslation } from "../../internationalisation/translation.hook";
import { CardStatus } from "../../components/card";
import { formatNewTaskDate } from "../shared/utility";
import { OfferResponseTaskDocument } from "../../task/task.model";
import { Message } from "../../components/Message";
import { SectionHeader } from "../../components/section/SectionHeader";
import { NewTaskResponseForm, NewTaskResponseFormIds, useNewTaskResponseDialog } from "../../components/form-item/client/application/NewTaskResponseDialog";
import { DeclineTaskReason, NewTaskResponseData } from "../../task/new-task-response-action.hook";
import { FormProps } from "../../components/form-item/framework/react/FormProvider";
import { MultiChoiceFormItem, SingleChoiceFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { FormController } from "../../components/form-item/framework/core/form.controller";
import { FloatingButton } from "../../components/floating-button/FloatingButton";
import { TaskOfferCard } from "./TaskOfferCard";
import { createBackstopperCardStatus } from "../current-tasks/CurrentTasksTab";
import { NewTaskGuideList } from "../../guides/NewTaskGuideList";
import { TaskResponseData } from "../../task/task-response-action.hook";
import { GardenerDocument } from "../../gardener/gardener.model";

interface NewTaskDetailPageProps {
    gardener: GardenerDocument;
    task: OfferResponseTaskDocument
    now: string;
    newTaskResponseAction: (data: NewTaskResponseData) => void;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const NewTaskDetailPage = (props: NewTaskDetailPageProps) => {
    const { gardener, task, now, newTaskResponseAction, taskResponseAction } = props;

    const { t } = useTranslation();

    const newTaskResponseActionAdapter = useCallback<FormProps<NewTaskResponseForm>["onSubmit"]>((controller, form): void => {
        const data = mapNewTaskResponseFormToNewTaskResponseData(controller, form, task.gardeningTaskId);

        newTaskResponseAction(data);
    }, [newTaskResponseAction, task.gardeningTaskId]);

    const openNewTaskResponseDialog = useNewTaskResponseDialog(now, newTaskResponseActionAdapter);

    return (
        <ContentSection>

            <Container maxWidth="sm" disableGutters>

                {(() => {
                    if ( task.reasonForClosing === "expired" ) return <Message text={t("NewTaskDetailPage: expired-message")} />;
                    if ( task.reasonForClosing === "declined" ) return <Message text={t("NewTaskDetailPage: declined-message")} />;
                    if ( task.reasonForClosing === "accepted" ) return <Message text={t("NewTaskDetailPage: accepted-message")} />;
                    if ( task.hasBeenPerformed ) return <Message text={t("OverviewTaskDetailPage: task-has-already-been-performed")} />;

                    return (
                        <Section>

                            <TaskOfferCard
                                status={(
                                    <>
                                        {task.backstopper ? createBackstopperCardStatus() : null}
                                        <CardStatus color="warning" status={formatNewTaskDate(task.performAfterDate, now, t)} />
                                    </>
                                )}
                                title={t("CurrentTasksTab: new-task-title")}
                                task={task}
                            />

                            <FloatingButton>
                                <VerticalButtons>
                                    <GreenButton onClick={openNewTaskResponseDialog}>{t("CurrentTasksTab: task-response-action")}</GreenButton>
                                </VerticalButtons>
                            </FloatingButton>

                        </Section>
                    );
                })()}

            </Container>

            <Container maxWidth="md" disableGutters>
                <Section>
                    <SectionHeader>Relevante guides</SectionHeader>
                    <NewTaskGuideList gardener={gardener} taskResponseAction={taskResponseAction} />
                </Section>
            </Container>

        </ContentSection>
    );
};

const mapNewTaskResponseFormToNewTaskResponseData = (controller: FormController, form: NewTaskResponseForm, gardeningTaskId: string): NewTaskResponseData => {
    const areYouInterested = controller.getItem(NewTaskResponseFormIds.SingleChoiceAreYouInterested, form) as SingleChoiceFormItem<FormItem[]>;

    const action: NewTaskResponseData["action"] = (() => {
        if ( areYouInterested.selectedChoiceIndex === 0 ) return "decline";
        if ( areYouInterested.selectedChoiceIndex === 1 ) return "accept";

        throw new Error(`Unexpected action: ${areYouInterested.selectedChoiceIndex}`);
    })();

    if ( action === "accept" ) return { action: "accept", gardeningTaskId };

    const whyIsTheTaskIrrelevant = controller.getItem(NewTaskResponseFormIds.MultiChoiceWhyIsTheTaskIrrelevant, form) as MultiChoiceFormItem<FormItem[], FormItem>;

    const reasons: DeclineTaskReason[] = whyIsTheTaskIrrelevant.selectedChoiceIndexes.map(x => {
        if ( x === 0 ) return "do-not-understand";
        if ( x === 1 ) return "do-not-have-time";
        if ( x === 2 ) return "cannot-perform";
        if ( x === 3 ) return "too-far-away-or-too-small";
        if ( x === 4 ) return "other";

        throw new Error(`Unexpected value`);
    });

    const reasonForDeclining = controller.getItem(NewTaskResponseFormIds.TextAreaReasonForDeclining, form) as TextAreaFormItem;

    const comment = reasonForDeclining.value;

    return {
        action: "decline",
        gardeningTaskId,
        reasons,
        comment: reasons.includes("other") ? comment : null,
    };
};
