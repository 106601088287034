import { useMemo } from "react";

import { makeStyles } from "@material-ui/core";

import { FormItemSupport } from "../../framework/core/form-item.support";

export interface InfoFormItem {
    type: "info",
    text: string;
    render: "default" | "info" | "warning" | "danger";
}

const useStyles = makeStyles({
    root: {
        lineHeight: "125%",

        "& strong": {
            fontWeight: 600,
        },

        "& a": {
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
        
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },

        "& ul": {
            listStyle: "none",
            paddingLeft: "8px",
            margin: 0,

            "& > li": {
                fontSize: "16px",
                fontWeight: 400,
                color: "#4A4A4A",
                lineHeight: "125%",
                paddingLeft: "13px",
            
                "&::before": {
                    content: '"\\2022"',
                    fontWeight: 600,
                    marginRight: "8px",
                    marginLeft: "-13px",
                },
            },
        },
    },
});

interface InfoFormItemComponentProps {
    item: InfoFormItem;
}

export const InfoFormItemComponent = (props: InfoFormItemComponentProps) => {
    const classes = useStyles();
    const { item } = props;

    const html = useMemo(() => ({ __html: item.text }), [item.text]);

    return <div dangerouslySetInnerHTML={html} className={classes.root} />;
};

export class InfoFormItemSupport implements FormItemSupport<InfoFormItem> {
    supportedType = "info" as const;
    updateChildren = undefined;
    markDirty = undefined;

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(): boolean {
        return false;
    }
}
