import { useCallback, useMemo } from "react";

import { makeStyles } from "@material-ui/core";

import { CardContent } from "../../components/card/CardContent";
import { PositiveAction } from "../../components/card/PositiveAction";
import { CardActions } from "../../components/card/CardActions";
import { Notice } from "../../components/notice/Notice";
import { useTranslation } from "../../internationalisation/translation.hook";
import { Card, CardHeader } from "../../components/card";
import { BankAccount } from "../personal-information.model";
import { RequestPayoutForm, RequestPayoutFormIds, useRequestPayoutDialog } from "./RequestPayoutDialog";
import { FormController } from "../../components/form-item/framework/core/form.controller";
import { TextAreaFormItem } from "../../components/form-item/client/components";
import { RequestGardenerPayoutData } from "./request-payout.hook";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
});

interface CommissionPayoutCardProps {
    balance: number;
    amountScheduled: number | null;
    payoutInProgress: boolean;
    bankAccount: BankAccount | null;
    onRequestPayout: (data: RequestGardenerPayoutData) => void;
}

export const CommissionPayoutCard = (props: CommissionPayoutCardProps) => {
    const classes = useStyles();
    const { formatCurrency } = useTranslation();

    const { payoutInProgress, amountScheduled, balance, bankAccount, onRequestPayout } = props;

    const notice = useMemo(() => {
        if ( payoutInProgress ) return <Notice>Vi har modtaget din anmodning om at få udbetalt honorar.</Notice>;
        if ( balance <= 0 ) return <Notice>Din saldo skal være positiv for at få udbetalt honorar.</Notice>;

        return null;
    }, [payoutInProgress, balance]);

    const balanceView = useMemo(() => {
        if ( payoutInProgress && amountScheduled === null ) {
            return (
                <div className={classes.group}>
                    <label>Saldo (udbetaling behandles)</label>
                    <span>{formatCurrency(balance)}</span>
                </div>
            );
        }

        if ( payoutInProgress && amountScheduled !== null ) {
            return (
                <>
                    <div className={classes.group}>
                        <label>Saldo (efter udbetaling)</label>
                        <span>{formatCurrency(balance - amountScheduled)}</span>
                    </div>

                    <div className={classes.group}>
                        <label>Sat til udbetaling</label>
                        <span>{formatCurrency(amountScheduled)}</span>
                    </div>
                </>
            );
        }

        return (
            <div className={classes.group}>
                <label>Saldo</label>
                <span>{formatCurrency(balance)}</span>
            </div>
        )
    }, [payoutInProgress, amountScheduled, balance, classes.group, formatCurrency]);

    const requestPayoutAdapter = useCallback((controller: FormController, item: RequestPayoutForm) => {
        const registrationNumber = (controller.getItem(RequestPayoutFormIds.TextAreaRegistrationNumber, item) as TextAreaFormItem).value;
        const accountNumber = (controller.getItem(RequestPayoutFormIds.TextAreaAccountNumber, item) as TextAreaFormItem).value;

        onRequestPayout({
            bankAccount: {
                registrationNumber,
                accountNumber,
            },
        });
    }, [onRequestPayout]);
    const openRequestPayoutDialog = useRequestPayoutDialog(bankAccount, requestPayoutAdapter);

    return (
       <Card>
            <CardHeader title="Udbetaling af honorar" />

            <CardContent>

                <div className={classes.group}>
                    <label>Sådan optjener du honorar</label>
                    <span>Når du løser opgaver og registrerer tid, bliver dine kunder automatisk faktureret på dine vegne. Vi står for opkrævning af betaling. Du optjener dit honorar, når kunderne betaler.</span>
                </div>

                {balanceView}

                {bankAccount ? (
                    <div className={classes.group}>
                        <label>Senest anvendte bankkonto</label>
                        <span>{bankAccount.registrationNumber} {bankAccount.accountNumber}</span>
                    </div>
                ) : null}

                {notice}

            </CardContent>

            <CardActions>
                <PositiveAction disabled={Boolean(notice)} onClick={openRequestPayoutDialog}>Udbetal honorar</PositiveAction>
            </CardActions>

        </Card>
    );
};
