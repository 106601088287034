import { useMemo } from "react";

import { GroupFormItem, PageFormItem, useFormDialog, NoticeFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormProps } from "../../components/form-item/framework/react/FormProvider";
import { BankAccount } from "../personal-information.model";

export enum RequestPayoutFormIds {
    TextAreaRegistrationNumber = "TextAreaRegistrationNumber",
    TextAreaAccountNumber = "TextAreaAccountNumber",
}

export type RequestPayoutForm =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            TextAreaFormItem,
            TextAreaFormItem,
            NoticeFormItem,
        ]>
    >;

export const useRequestPayoutDialog = (bankAccount: BankAccount | null, onConfirm: FormProps<RequestPayoutForm>["onSubmit"]) => {
    const form: RequestPayoutForm = useMemo(() => {

        const informationNotice: NoticeFormItem = {
            type: "notice",
            render: "info",
            html: "Din saldo udbetales til din bankkonto hurtigst muligt, typisk inden for 1-2 bankdage.",
        };

        const registrationNumberTextArea: TextAreaFormItem = {
            id: RequestPayoutFormIds.TextAreaRegistrationNumber,
            type: "text-area",
            render: "text-field",
            label: "Registreringsnummer",
            placeholder: null,
            value: bankAccount?.registrationNumber ?? "",
            dirty: false,
            validation: "bank registration number",
        };

        const accountNumberTextArea: TextAreaFormItem = {
            id: RequestPayoutFormIds.TextAreaAccountNumber,
            type: "text-area",
            render: "text-field",
            label: "Kontonummer",
            placeholder: null,
            value: bankAccount?.accountNumber ?? "",
            dirty: false,
            validation: "bank account number",
        };

        const warningNotice: NoticeFormItem = {
            type: "notice",
            render: "warning",
            html: `
                <strong>OBS:</strong> Du skal være indehaver af den angivne bankkonto.
            `,
        };

        return {
            type: "page",
            title: "Udbetal honorar",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    informationNotice,
                    registrationNumberTextArea,
                    accountNumberTextArea,
                    warningNotice,
                ],
            },
        };
    }, [bankAccount]);

    return useFormDialog(form, onConfirm);
};
