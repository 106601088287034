import { useCallback, useEffect, useMemo, useRef } from "react";

import { Collapse } from "@material-ui/core";

import { AlternativeHint, Group, Label } from "../../../form";
import { useForm } from "../../framework/react/FormProvider";
import { FormItemSupport } from "../../framework/core/form-item.support";
import { MultipleChoiceGroup } from "../../../form/multiple-choice";
import { Validation } from "../../../validation/validation";

export interface VerticalButtonsFormItem {
    id: string;
    type: "vertical-buttons",
    mode: "regular" | "switch page";
    label: string | null;
    answerTexts: string[];
    selectedChoiceIndex: number | null;
    dirty: boolean;
    validIndexes?: number[];
}

interface VerticalButtonsFormItemComponentProps {
    item: VerticalButtonsFormItem;
}

export const VerticalButtonsFormItemComponent = (props: VerticalButtonsFormItemComponentProps) => {
    const { item } = props;
    const { validationAnimationDuration, updateItem, goForward } = useForm();

    const validation = useMemo(() => validate(item), [item]);
    const completed = validation?.severity !== "error";

    const shouldGoForward = useRef(false);
    useEffect(() => {
        if ( completed && shouldGoForward.current ) {
            shouldGoForward.current = false;
            goForward();
        }
    }, [completed, goForward]);

    const selectAnswer = useCallback((selectedChoiceIndexesAsStrings: string[]) => {
        const selectedChoiceIndexes = selectedChoiceIndexesAsStrings.map(x => parseInt(x, 10)).filter(x => x !== item.selectedChoiceIndex);
        const selectedChoiceIndex = selectedChoiceIndexes.length === 1 ? selectedChoiceIndexes[0] : item.selectedChoiceIndex;

        if ( item.mode === "switch page" ) {
            shouldGoForward.current = true;
        }

        updateItem(item.id, (state: VerticalButtonsFormItem) => ({
            ...state,
            selectedChoiceIndex,
            dirty: true,
        }));
    }, [updateItem, item.id, item.selectedChoiceIndex, item.mode]);

    const selectedChoiceIndexesAsStrings = useMemo(() => item.selectedChoiceIndex !== null ? [item.selectedChoiceIndex.toString()] : [], [item.selectedChoiceIndex]);

    const options = useMemo<Array<{ id: string, text: string}>>(() => {
        return item.answerTexts.map((answerText, index) => ({ id: index.toString(), text: answerText }));
    }, [item.answerTexts]);

    const colorStatus = useMemo(() => {
        if ( validation?.severity === "error" ) return "negative";
        if ( validation?.severity === "success" ) return "positive";

        return undefined;
    }, [validation]);

    return (
        <div id={`${item.id}-section`} key={`${item.id}-section`}>
            {item.label ? <Label style={{ marginBottom: "8px" }}>{item.label}</Label> : null}

            <Group error={Boolean(item.dirty && validation?.severity === "error")}>

                <MultipleChoiceGroup
                    options={options}
                    value={selectedChoiceIndexesAsStrings}
                    onChange={selectAnswer}
                    colorStatus={colorStatus}
                />

                <Collapse in={Boolean(item.dirty && validation)} timeout={validationAnimationDuration}>
                    <AlternativeHint severity={validation?.severity} message={validation?.message} />
                </Collapse>
            </Group>
        </div>
    );
};

export class VerticalButtonsFormItemSupport implements FormItemSupport<VerticalButtonsFormItem> {
    supportedType = "vertical-buttons" as const;
    updateChildren = undefined;

    markDirty(item: VerticalButtonsFormItem): VerticalButtonsFormItem {
        return { ...item, dirty: true };
    }

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(item: VerticalButtonsFormItem): boolean {
        return validate(item)?.severity === "error";
    }
}

const validate = (item: VerticalButtonsFormItem): Validation => {
    if ( item.selectedChoiceIndex === null ) return { severity: "error", message: "Vælg et svar" };
    if ( item.validIndexes && !item.validIndexes.includes(item.selectedChoiceIndex) ) return { severity: "error", message: "Svaret er forkert. Prøv igen" };
    if ( item.validIndexes && item.validIndexes.includes(item.selectedChoiceIndex) ) return { severity: "success", message: "Svaret er rigtigt!" };

    return undefined;
}
