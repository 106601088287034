import { useCallback } from "react";
import { useHistory } from "react-router";

import { LinearProgress } from "../../application/LinearProgress";
import { useUser } from "../../user/UserProvider";
import { PastCustomersTab } from "./PastCustomersTab";
import { useAllMatches } from "../../application/matches.hook";

export const IntegratedPastCustomersTab = () => {
    const gardener = useUser();

    const matches = useAllMatches(gardener.id, "past customers");

    const history = useHistory();
    const goToCustomer = useCallback((matchId: string) => {
        history.push(`/customers/past/${matchId}`);
    }, [history]);

    if ( !matches ) return <LinearProgress />;

    return (
        <PastCustomersTab
            goToCustomer={goToCustomer}
            matches={matches}
        />
    );
};
