import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "24px",

        [theme.breakpoints.down(500)]: {
            padding: "24px 16px",
        },
    },
    title: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#4A4A4A",
        margin: 0,
    },
}));

interface AlternativeCardHeaderProps {
    title: string;
}

export const AlternativeCardHeader = (props: AlternativeCardHeaderProps) => {
    const classes = useStyles();

    const { title } = props;

    return (
        <div className={classes.root}>
            <h3 className={classes.title}>{title}</h3>
        </div>
    );
};
