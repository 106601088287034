import { useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { LinearProgress } from "../../application/LinearProgress";
import { useTasksForGardenerWithGardeningTaskId } from "../../task/tasks.hook";
import { useUser } from "../../user/UserProvider";
import { OfferResponseTaskDocument } from "../../task/task.model";

const useQuery = () => {
    const location = useLocation();

    return new URLSearchParams(location.search);
};

export const RedirectToNewTaskTask = () => {
    const { gardeningTaskId } = useParams<{ gardeningTaskId: string }>();

    const query = useQuery();

    const status = (() => {
        switch ( query.get("status") ) {
            case "performed": return "performed";
            default: return "ignore";
        }
    })();

    const gardener = useUser();
    const types = useRef<OfferResponseTaskDocument["type"][]>(["offer-response"]).current;
    const tasks = useTasksForGardenerWithGardeningTaskId(gardener.id, gardeningTaskId, types);

    const history = useHistory();
    const goToList = useCallback(() => history.replace("/overview"), [history]);

    useEffect(() => {
        if ( tasks === undefined ) return;

        const task = tasks && tasks.length > 0 ? tasks[0] : null;
        if ( !task ) return goToList();

        if ( task.hasBeenPerformed && status === "performed" ) return goToList();
    }, [tasks, status, goToList]);

    return <LinearProgress />;
};
