import moment from "moment";

import { PageFormItem, GroupFormItem, DatePickerFormItem, InfoFormItem, SingleChoiceFormItem } from "../../components";

export interface PostponeContactBranchIds {
    singleChoiceDoYouExpectToFindDate: string;
    datePickerWhenDoYouExpectToFindDate: string;
}

export type PostponeContactBranch =
    PageFormItem<
        GroupFormItem<[
            SingleChoiceFormItem<[
                PageFormItem<InfoFormItem>,
                PageFormItem<
                    GroupFormItem<[
                        DatePickerFormItem,
                        PageFormItem<InfoFormItem>
                    ]>
                >,
                PageFormItem<InfoFormItem>,
            ]>
        ]>
    > | PageFormItem<InfoFormItem>

export const createPostponeContactBranch = (postponeDateMinimum: string, postponeDateMaximum: string, ids: PostponeContactBranchIds): PostponeContactBranch => {

    const theTimeHasAlreadyPassedConfirmationPage: PageFormItem<InfoFormItem> = {
        type: "page",
        title: "Luk opgave",
        item: {
            type: "info",
            render: "default",
            text: `
                Det er ikke lykkedes jer, at finde en dato for første besøg. Det tyder på, at kunden har været ude i for god tid og derfor lukkes opgaven.<br />
                <br />
                Vi kontakter kunden, så du slipper for at bruge tid på det, og vil så vidt muligt prioritere dig, hvis kunden får brug for hjælp igen.<br />
                <br />
                Du vil ikke længere se denne opgave på din oversigt.
            `
        },
    };

    const rejectCustomerConfirmationPage: PageFormItem<InfoFormItem> = {
        type: "page",
        title: "Luk opgave",
        item: {
            type: "info",
            render: "default",
            text: `
                Der er ikke umiddelbart udsigt til, at I finder en dato for første besøg. Det tyder på, at kunden har været ude i for god tid og derfor lukkes opgaven.<br />
                <br />
                Vi kontakter kunden, så du slipper for at bruge tid på det, og vil så vidt muligt prioritere dig, hvis kunden får brug for hjælp igen.<br />
                <br />
                Du vil ikke længere se denne opgave på din oversigt.
            `
        },
    };

    const postponeContactConfirmationPage: PageFormItem<InfoFormItem> = {
        type: "page",
        title: "Udskyd kontakt til kunde",
        item: {
            type: "info",
            render: "default",
            text: `
                Vi forventer at du <strong>ringer til kunden på den valgte dato</strong> for at aftale et besøg.<br />
                <br />
                Du vil se en “Har I lavet en aftale?”-opgave på din oversigt. Gå ind på opgaven for at besvare den, når du har ringet til kunden.<br />
                <br />
                Vi minder dig om opgaven med en sms på dagen.
            `,
        },
    };

    const theTimeHasAlreadyPassed = postponeDateMinimum > postponeDateMaximum;
    if ( theTimeHasAlreadyPassed ) return theTimeHasAlreadyPassedConfirmationPage;

    return {
        type: "page",
        title: "Vi kan ikke planlægge et besøg endnu",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    id: ids.singleChoiceDoYouExpectToFindDate,
                    type: "single-choice",
                    mode: "switch page",
                    render: "horizontal-buttons",
                    selectedChoiceIndex: null,
                    dirty: false,
                    questionText: `Forventer du, at I tales ved før ${moment(postponeDateMaximum).add(1, "day").format("D. MMMM")} for at aftale et besøg?`,
                    answerTexts: [
                        "Nej",
                        "Ja",
                        "Ved ikke",
                    ],
                    answerItems: [
                        rejectCustomerConfirmationPage,
                        {
                            type: "page",
                            title: "Hvornår forventer du, at I tales ved?",
                            item: {
                                type: "group",
                                render: "all at once",
                                items: [
                                    {
                                        id: ids.datePickerWhenDoYouExpectToFindDate,
                                        type: "date-picker",
                                        label: "Dato",
                                        value: "",
                                        dirty: false,
                                        minimumDate: postponeDateMinimum,
                                        maximumDate: postponeDateMaximum,
                                    },
                                    postponeContactConfirmationPage,
                                ],
                            },
                        },
                        rejectCustomerConfirmationPage,
                    ],
                },
            ]
        },
    };
};
