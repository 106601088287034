import { useCallback, useMemo } from "react";

import { Chip, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    root: {
        padding: "8px 24px",
        borderRadius: "24px",
        height: "auto",

        "& .MuiChip-label": {
            padding: 0,
            whiteSpace: "normal",
        },
    },
    offNeutral: {
        backgroundColor: "transparent",
        color: "#1C5B13",
        border: "1px solid #1C5B13",

        "&.disabled": {
            backgroundColor: "transparent",
            color: "#818181",
            border: "1px solid #818181",
        },
        
        "&:focus": {
            backgroundColor: "transparent",
        },

        "&:hover": {
            backgroundColor: "rgba(29, 93, 20, 0.08) !important",
        },
    },
    onNeutral: {
        backgroundColor: "#1C5B13",
        color: "#FFFFFF",
        border: "1px solid #1C5B13",

        "&.disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
            border: "1px solid #818181",
        },

        "&:focus": {
            backgroundColor: "#1C5B13",
        },

        "&:hover": {
            backgroundColor: "#2f931f",
        },
    },
    onNegative: {
        backgroundColor: "#F44336",
        color: "#FFFFFF",
        border: "1px solid #F44336",

        "&.disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
            border: "1px solid #818181",
        },

        "&:focus": {
            backgroundColor: "#F44336",
        },

        "&:hover": {
            backgroundColor: "#F44336",
        },
    },
    onPositive: {
        backgroundColor: "#2FA52B",
        color: "#FFFFFF",
        border: "1px solid #2FA52B",

        "&.disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
            border: "1px solid #818181",
        },

        "&:focus": {
            backgroundColor: "#2FA52B",
        },

        "&:hover": {
            backgroundColor: "#2FA52B",
        },
    },
    destructive: {
        backgroundColor: "#F44336",
        color: "#FFFFFF",
        border: "1px solid #F44336",

        "&.disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
            border: "1px solid #818181",
        },

        "&:focus": {
            backgroundColor: "#F44336",
        },

        "&:hover": {
            backgroundColor: "#da190b",
        },
    },
});

interface MultipleChoiceOptionProps<T> {
    id: T;
    text: string;
    on: boolean;
    onClick: (id: T) => void;
    disabled?: boolean;
    destructive?: boolean;
    colorStatus?: "negative" | "positive";
}

export const MultipleChoiceOption = <T extends unknown>(props: MultipleChoiceOptionProps<T>) => {
    const classes = useStyles();

    const { id, text, on, onClick, disabled, destructive, colorStatus } = props;

    const { onClasses, offClasses, variant } = useMemo(() => {
        if ( destructive ) return { onClasses: classes.destructive, offClasses: classes.destructive, variant: "default" as const };

        const onClasses = (() => {
            if ( colorStatus === "negative" ) return classes.onNegative;
            if ( colorStatus === "positive" ) return classes.onPositive;

            return classes.onNeutral;
        })();

        const offClasses = classes.offNeutral;
        const variant = on ? "default" as const : "outlined" as const;

        return { onClasses, offClasses, variant };
    }, [destructive, on, classes, colorStatus]);

    const stateClass = on ? onClasses : offClasses;
    

    const disabledClass = disabled ? "disabled" : "active";

    const click = useCallback(() => {
        onClick(id);
    }, [onClick, id]);

    return (
        <Chip
            className={`${classes.root} ${stateClass} ${disabledClass}`}
            variant={variant}
            label={text}
            onClick={click}
            disabled={disabled}
        />
    );
}
