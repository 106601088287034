import { Button, makeStyles } from "@material-ui/core";

import { AccordionList } from "../components/accordion/AccordionList";
import { AccordionListItem } from "../components/accordion/AccordionListItem";
import { UnorderedList } from "../components/unordered-list/UnorderedList";
import { useAppointmentTaskGuideDialog } from "./AppointmentTaskGuide";
import { useCustomerConversationGuideDialog } from "./CustomerConversationGuide";
import { useAfterCloseTaskGuideDialog } from "./AfterCloseTaskGuide";
import { afterClosedTaskGuideContentBullets, appointmentTaskGuideContentBullets, customerConversationGuideContentBullets } from "./TutorialPage";
import { GardenerDocument, Guide } from "../gardener/gardener.model";
import { TaskResponseData } from "../task/task-response-action.hook";
import { useCallback } from "react";

const useStyles = makeStyles({
    content: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        "& > *": {
            marginTop: 0,
            marginBottom: "16px",

            "&:last-child": {
                marginBottom: 0,
            },
        },

        "& > ul": {
            marginTop: "-16px",

            "&:first-child": {
                marginTop: 0,
            },
        },

        "& > button": {
            padding: 0,
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
            textTransform: "none",
    
            "&:hover": {
                backgroundColor: "transparent",
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
});

export interface AppointmentTaskGuideListProps {
    gardener: GardenerDocument;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const AppointmentTaskGuideList = (props: AppointmentTaskGuideListProps) => {
    const classes = useStyles();
    const { gardener, taskResponseAction } = props;

    const markLookupGuideCompleted = useCallback((guide: Guide) => {
        if ( gardener.tutorial.completedGuides.includes(guide) ) return;

        taskResponseAction({ action: "mark guide completed", mode: "lookup", guide });
    }, [taskResponseAction, gardener]);

    const markAppointmentTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("appointmentTask"), [markLookupGuideCompleted]);
    const startAppointmentTaskGuide = useAppointmentTaskGuideDialog(markAppointmentTaskGuideCompleted);

    const markCustomerConversationGuideCompleted = useCallback(() => markLookupGuideCompleted("customerConversation"), [markLookupGuideCompleted]);
    const startCustomerConversationGuide = useCustomerConversationGuideDialog(markCustomerConversationGuideCompleted);

    const markAfterCloseTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("afterClosedTask"), [markLookupGuideCompleted]);
    const startAfterCloseTaskGuide = useAfterCloseTaskGuideDialog(markAfterCloseTaskGuideCompleted);

    return (
        <AccordionList>

            <AccordionListItem id="appointment-task-guide-1" title="Sådan besvarer du “Har I lavet en aftale?”">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {appointmentTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startAppointmentTaskGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

            <AccordionListItem id="appointment-task-guide-2" title="Samtalen med kunden">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {customerConversationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startCustomerConversationGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

            <AccordionListItem id="appointment-task-guide-3" title="Hvad sker der, hvis du lukker en opgave?">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {afterClosedTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startAfterCloseTaskGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

        </AccordionList>
    );
};
