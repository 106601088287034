import { IconButton, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";

import { CardContent } from "../components/card/CardContent";
import { useTranslation } from "../internationalisation/translation.hook";
import { GardenerDocument } from "./gardener.model";
import { Card, CardHeader } from "../components/card";
import { TaskResponseData } from "../task/task-response-action.hook";
import { UpdateGardenerFormIds, useUpdateGardenerDialog } from "./UpdateGardenerDialog";
import { useCallback } from "react";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { FormItem } from "../components/form-item/framework/core/form-item.model";
import { TextAreaFormItem } from "../components/form-item/client/components";
import { AddressPickerFormItem } from "../components/form-item/client/form-item/AddressPickerFormItemComponent";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
    editIcon: {
        fontSize: "24px",
        color: "#297A1D",
    },
});

interface GardenerCardProps {
    gardener: GardenerDocument;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const GardenerCard = (props: GardenerCardProps) => {
    const classes = useStyles();

    const { gardener, taskResponseAction } = props;
    const { t } = useTranslation();

    const updateGardenerResponseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, form): void => {
        const externalAddressId = (controller.getItem(UpdateGardenerFormIds.AddressPickerAddress, form) as AddressPickerFormItem).value!.externalId;
        const phoneNumber = (controller.getItem(UpdateGardenerFormIds.TextAreaPhoneNumber, form) as TextAreaFormItem).value;
        const email = (controller.getItem(UpdateGardenerFormIds.TextAreaEmail, form) as TextAreaFormItem).value;

        taskResponseAction({
            action: "update gardener",
            email,
            phoneNumber,
            externalAddressId,
        })
    }, [taskResponseAction]);
    const openUpdateGardenerDialog = useUpdateGardenerDialog(gardener, updateGardenerResponseAdapter);

    const editButton = (
        <IconButton aria-label="edit" onClick={openUpdateGardenerDialog}>
            <EditIcon className={classes.editIcon} />
        </IconButton>
    );

    return (
        <Card>
            <CardHeader title={t("GardenerCard: card-title")} button={editButton} />

            <CardContent>

                <div className={classes.group}>
                    <label>{t("GardenerCard: working-as-label")}</label>
                    {gardener.cvr && gardener.paysVat ? <span>{t("GardenerCard: working-as-company-with-vat-value")}</span> : null}
                    {gardener.cvr && !gardener.paysVat ? <span>{t("GardenerCard: working-as-company-without-vat-value")}</span> : null}
                    {!gardener.cvr ? <span>{t("GardenerCard: working-as-private-person-value")}</span> : null}
                </div>

                {gardener.cvr ? (
                    <div className={classes.group}>
                        <label>{t("GardenerCard: company-label")}</label>
                        <span>
                            {t("GardenerCard: company-value")
                                .replace("$COMPANY_NAME", gardener.companyName)
                                .replace("$CVR", gardener.cvr)}
                        </span>
                    </div>
                ) : null}

                {gardener.cvr ? (
                    <div className={classes.group}>
                        <label>{t("GardenerCard: contact-person-label")}</label>
                        <span>{gardener.name}</span>
                    </div>
                ) : (
                    <div className={classes.group}>
                        <label>{t("GardenerCard: name-label")}</label>
                        <span>{gardener.name}</span>
                    </div>
                )}

                <div className={classes.group}>
                    <label>{t("GardenerCard: address-label")}</label>
                    <span>
                        {gardener.address.street}<br />
                        {gardener.address.zipCode}
                    </span>
                </div>

                <div className={classes.group}>
                    <label>{t("GardenerCard: email-label")}</label>
                    <span>{gardener.email}</span>
                </div>

                <div className={classes.group}>
                    <label>{t("GardenerCard: phone-label")}</label>
                    <span>{gardener.phoneNumber}</span>
                </div>

            </CardContent>
        </Card>
    );
};
