import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef } from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

import moment from "moment";

import { useUser } from "./user/UserProvider";
import { useTranslation } from "./internationalisation/translation.hook";
import { formatTitle } from "./utility";
import { useTasks } from "./task/TaskProvider";

interface LinkValue {
    sideLinks: JSX.Element | null;
    topLinks: (JSX.Element | "help-button" | "guide-button")[];
    logOutLink: JSX.Element;
}

const LinkContext = createContext<LinkValue | null | undefined>(undefined);

export const LinkProvider = (props: PropsWithChildren<{}>) => {
    const gardener = useUser();

    const { t } = useTranslation();
    
    const history = useHistory();
    const logOut = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        
        history.replace("/logout")
    }, [history]);

    const now = useRef(moment().toISOString(true)).current;
    const tasks = useTasks();
    const relevantTasks = useMemo(() => {
        return tasks.filter(task => task.type === "offer-response" || moment(task.performAfterDate).isSameOrBefore(now, "day"));
    }, [tasks, now]);

    const value = useMemo(() => {

        const sideLinks = gardener.status === "active" || gardener.status === "terminated" ? (
            <>
                <NavLink to="/overview">{formatTitle(t("LeftTabBar: overview-tab"), relevantTasks.length)}</NavLink>
                <NavLink exact to="/registrations">{t("LeftTabBar: registrations-tab")}</NavLink>
                <NavLink to="/customers">{t("LeftTabBar: customers-tab")}</NavLink>
            </>
        ) : null;

        const topLinks: (JSX.Element | "help-button" | "guide-button")[] = [];
        topLinks.push(<NavLink key="profile" exact to="/profile">{t("TopBar: profile-link")}</NavLink>);
        topLinks.push("help-button");
        if ( gardener.status === "active" || gardener.status === "terminated" ) {
            topLinks.push("guide-button");
        } 
        topLinks.push(<NavLink key="agreement" exact to="/agreements/create">{t("TopBar: agreement-link")}</NavLink>);

        const logOutLink = <a href="/" onClick={logOut}>{t("TopBar: log-out-link")}</a>;

        return { sideLinks, topLinks, logOutLink };
    }, [logOut, t, gardener, relevantTasks]);

    return (
        <LinkContext.Provider value={value}>
            {props.children}
        </LinkContext.Provider>
    );
};

export const useLinks = () => {
    return useContext(LinkContext);
};
