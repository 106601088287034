import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum RegistrationGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
    Question5VerticalButtons = "Question5VerticalButtons",
}

export type RegistrationGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useRegistrationGuideDialog = (onConfirm: FormProps<RegistrationGuideForm>["onSubmit"]) => {
    const form: RegistrationGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        questionPages.push({
            type: "page",
            title: "Fakturering af kunde",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du registrerer tid, skal du skrive en beskrivelse af hvad du lavede, hvor lang tid det tog og hvilke redskaber der blev brugt.<br />
                            <br />
                            Så snart du har registreret tid, bliver kunden automatisk faktureret på dine vegne. Vær derfor altid omhyggelig med din tidsregistrering.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Dine beskrivelser kommer på kundens faktura. Undlad derfor at skrive noget, der ikke giver mening.
                        `,
                    },
                    {
                        id: RegistrationGuideFormIds.Question1VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad sker der, når jeg registrerer tid?",
                        answerTexts: [
                            "Kunden modtager automatisk en faktura",
                            "Go Go Garden tjekker min tidsregistrering",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Effektiv arbejdstid",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Du skal registrere din effektive arbejdstid og kunden forventer, at du forsøger at være så præcis som mulig.<br />
                            <br />
                            Vær særligt opmærksom på dette, hvis du f.eks. har holdt større pauser eller hvis du er kommet/gået på et skævt klokkeslæt.<br />
                            <br />
                            Hvis du har delt opgaven op i flere dele, så vær opmærksom på, at det samlede antal timer passer.
                        `,
                    },
                    {
                        id: RegistrationGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad skal jeg registrere?",
                        answerTexts: [
                            "Al den tid jeg var hos kunden",
                            "Min effektive arbejdstid, så præcist som muligt",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Opdeling af tidsregistrering",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Det er en god ide at dele opgaven op i flere dele og udspecificere, hvad du har lavet. Det kan du gøre ved at bruge knappen “Tilføj opgave”.<br />
                            <br />
                            På den måde kan du f.eks. angive, at du har brugt 2 timer på hækklipning og 30 minutter på bortkørsel af haveaffald.
                        `,
                    },
                    {
                        id: RegistrationGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan deler jeg opgaver op i flere dele?",
                        answerTexts: [
                            "Med knappen “Tilføj opgave”",
                            "Ved at registrere tid flere gange",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Brug af blandede redskaber",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Kundens timepris afhænger af, om du har brugt kundens redskaber, dine egne redskaber eller dine egne store redskaber.<br />
                            <br />
                            Du er derfor nødt til at dele opgaven op i flere dele, hvis du f.eks. både har brugt kundens redskaber og dine egne redskaber. Dette kan du gøre med “Tilføj opgave”.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Deler du ikke din registrering op efter redskaber, kan det føre til en reklamation fra kunden.
                        `,
                    },
                    {
                        id: RegistrationGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan registrerer jeg, at jeg har brugt blandede redskaber?",
                        answerTexts: [
                            "Jeg registrerer hvor lang tid jeg har brugt med de forskellige redskaber ved at bruge “Tilføj opgave”",
                            "Jeg angiver det i beskrivelsen",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Brug af medhjælper",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Har du aftalt med kunden, at du medbringer en medhjælper, så registrerer du tid for jer begge.<br />
                            <br />
                            Vi anbefaler, at du registrerer jeres tidsforbrug hver for sig ved at bruge “Tilføj opgave”. Du kan f.eks. registrere:
                            <ul>
                                <li>“Havemand: Hækklipning”</li>
                                <li>“Medhjælper: Græsslåning”</li>
                            </ul>
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Vær særligt opmærksom på spildtid. Opstår der ventetid for en af jer, er det ikke effektiv arbejdstid.
                        `,
                    },
                    {
                        id: RegistrationGuideFormIds.Question5VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan anbefales det, at jeg registrerer tid med en medhjælper?",
                        answerTexts: [
                            "At jeg registrerer vores arbejdstid samlet",
                            "At jeg registrerer vores arbejdstid hver for sig ved at bruge “Tilføj opgave”",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide og er nu klædt på til at registrere tid.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
