import { Container } from "@material-ui/core";

import { ContentSection } from "../application/ContentSection";
import { TitleHeader } from "../components/page/TitleHeader";
import { useOnboardingGuideDialog } from "./OnboardingGuideDialog";
import { useNewTaskGuideDialog } from "./NewTaskGuide";
import { Section } from "../components/section/Section";
import { useAppointmentTaskGuideDialog } from "./AppointmentTaskGuide";
import { useCustomerConversationGuideDialog } from "./CustomerConversationGuide";
import { useRegistrationTaskGuideDialog } from "./RegistrationTaskGuide";
import { useRegistrationGuideDialog } from "./RegistrationGuide";
import { useAfterRegistrationGuideDialog } from "./AfterRegistrationGuide";
import { useAfterCloseTaskGuideDialog } from "./AfterCloseTaskGuide";
import { GuideCard } from "./GuideCard";
import { UnorderedList } from "../components/unordered-list/UnorderedList";
import { GardenerDocument } from "../gardener/gardener.model";
import { TaskResponseData } from "../task/task-response-action.hook";
import { useCallback } from "react";
import { BackButton } from "../components/page/BackButton";

export interface TutorialPageProps {
    guide: keyof GardenerDocument["tutorial"];
    goBack?: () => void;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const TutorialPage = (props: TutorialPageProps) => {
    const { guide, goBack, taskResponseAction } = props;

    const markOnboardingGuideCompleted = useCallback(() => taskResponseAction({ action: "mark guide completed", mode: "tutorial", guide: "onboarding" }), [taskResponseAction]);
    const startOnboardingGuide = useOnboardingGuideDialog("tutorial", markOnboardingGuideCompleted);

    const markNewTaskGuideCompleted = useCallback(() => taskResponseAction({ action: "mark guide completed", mode: "tutorial", guide: "newTask" }), [taskResponseAction]);
    const startNewTaskGuide = useNewTaskGuideDialog(markNewTaskGuideCompleted);

    const markAppointmentTaskGuideCompleted = useCallback(() => taskResponseAction({ action: "mark guide completed", mode: "tutorial", guide: "appointmentTask" }), [taskResponseAction]);
    const startAppointmentTaskGuide = useAppointmentTaskGuideDialog(markAppointmentTaskGuideCompleted);

    const markCustomerConversationGuideCompleted = useCallback(() => taskResponseAction({ action: "mark guide completed", mode: "tutorial", guide: "customerConversation" }), [taskResponseAction]);
    const startCustomerConversationGuide = useCustomerConversationGuideDialog(markCustomerConversationGuideCompleted);

    const markRegistrationTaskGuideCompleted = useCallback(() => taskResponseAction({ action: "mark guide completed", mode: "tutorial", guide: "registrationTask" }), [taskResponseAction]);
    const startRegistrationTaskGuide = useRegistrationTaskGuideDialog(markRegistrationTaskGuideCompleted);

    const markRegistrationGuideCompleted = useCallback(() => taskResponseAction({ action: "mark guide completed", mode: "tutorial", guide: "registration" }), [taskResponseAction]);
    const startRegistrationGuide = useRegistrationGuideDialog(markRegistrationGuideCompleted);

    const markAfterRegistrationGuideCompleted = useCallback(() => taskResponseAction({ action: "mark guide completed", mode: "tutorial", guide: "afterRegistration" }), [taskResponseAction]);
    const startAfterRegistrationGuide = useAfterRegistrationGuideDialog("tutorial", markAfterRegistrationGuideCompleted);

    const markAfterClosedTaskGuideCompleted = useCallback(() => taskResponseAction({ action: "mark guide completed", mode: "tutorial", guide: "afterClosedTask" }), [taskResponseAction]);
    const startAfterClosedTaskGuide = useAfterCloseTaskGuideDialog(markAfterClosedTaskGuideCompleted);

    const backButton = goBack ? <BackButton onClick={goBack} /> : undefined;

    return (
        <>
            <TitleHeader button={backButton}>Information fra Go Go Garden</TitleHeader>

            <ContentSection>

                <Container maxWidth="md" disableGutters>

                    {guide === "onboarding" ? (
                        <Section>
                            <GuideCard title="Din ansøgning er blevet godkendt!" startGuide={startOnboardingGuide}>
                                <p>Inden vi slipper dig løs, vil vi gerne fortælle dig lidt om:</p>
                                <UnorderedList>
                                    {onboardingGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                </UnorderedList>
                                <p>Når du har gennemført guiden, er du klar til din første opgave.</p>
                            </GuideCard>
                        </Section>
                    ) : null}

                    {guide === "newTask" ? (
                        <Section>
                            <GuideCard title="Du er blevet tilbudt din første opgave!" startGuide={startNewTaskGuide}>
                                <p>Inden du besvarer den vil vi gerne fortælle dig lidt om:</p>
                                <UnorderedList>
                                    {newTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                </UnorderedList>
                                <p>Når du har gennemført guiden, kan du læse opgaven igennem og besvare den.</p>
                            </GuideCard>
                        </Section>
                    ) : null}

                    {guide === "appointmentTask" ? (
                        <Section>
                            <GuideCard title="Du har sagt ja tak til din første opgave!" startGuide={startAppointmentTaskGuide}>
                                <p>Derfor er der oprettet en “Har I lavet en aftale?”-opgave på din oversigt.</p>
                                <p>Inden du går videre, vil vi gerne fortælle dig lidt om:</p>
                                <UnorderedList>
                                    {appointmentTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                </UnorderedList>
                            </GuideCard>
                        </Section>
                    ) : null}

                    {guide === "customerConversation" ? (
                        <Section>
                            <GuideCard title="Du har fået din første “Har I lavet en aftale?”-opgave" startGuide={startCustomerConversationGuide}>
                                <p>Inden du ringer til kunden vil vi gerne fortælle dig lidt om:</p>
                                <UnorderedList>
                                    {customerConversationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                </UnorderedList>
                                <p>Når du har gennemført guiden, kan du ringe til kunden for at aftale et besøg.</p>
                            </GuideCard>
                        </Section>
                    ) : null}

                    {guide === "registrationTask" ? (
                        <Section>
                            <GuideCard title="Du har planlagt dit første besøg!" startGuide={startRegistrationTaskGuide}>
                                <p>Derfor er der oprettet en “Hvordan gik dit besøg?”-opgave på din oversigt.</p>
                                <p>Inden du går videre, vil vi gerne fortælle dig lidt om:</p>
                                <UnorderedList>
                                    {registrationTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                </UnorderedList>
                            </GuideCard>
                        </Section>
                    ) : null}

                    {guide === "registration" ? (
                        <Section>
                            <GuideCard title="Du har fået din første “Hvordan gik dit besøg?”-opgave" startGuide={startRegistrationGuide}>
                                <p>Inden du registrerer tid vil vi gerne fortælle dig lidt om:</p>
                                <UnorderedList>
                                    {registrationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                </UnorderedList>
                                <p>Når du har gennemført guiden, kan du registrere tid for dit besøg hos kunden.</p>
                            </GuideCard>
                        </Section>
                    ) : null}

                    {guide === "afterRegistration" ? (
                        <Section>
                            <GuideCard title="Du har løst din første opgave og er næsten færdig med vores onboarding" startGuide={startAfterRegistrationGuide}>
                                <p>Til slut vil vi gerne fortælle dig lidt om:</p>
                                <UnorderedList>
                                    {afterRegistrationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                </UnorderedList>
                            </GuideCard>
                        </Section>
                    ) : null}

                    {guide === "afterClosedTask" ? (
                        <Section>
                            <GuideCard title="Du har lukket din første opgave" startGuide={startAfterClosedTaskGuide}>
                                <p>Inden du fortsætter, vil vi fortælle dig lidt om:</p>
                                <UnorderedList>
                                    {afterClosedTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                </UnorderedList>
                            </GuideCard>
                        </Section>
                    ) : null}

                </Container>

            </ContentSection>
        </>
    );
};

export const onboardingGuideContentBullets = [
    "hvordan konceptet fungerer",
    "hvordan du tjener penge",
    "hvordan vi hjælper dig i gang ved hjælp af vores guides",
    "hvordan du beder os om hjælp",
    "hvordan det fungerer, når du bliver tilbudt nye opgaver"
];

export const newTaskGuideContentBullets = [
    "hvordan opgaven skal forstås",
    "hvordan du besvarer opgaven",
    "hvad du forpligter dig til ved at sige ja tak til opgaven",
];

export const appointmentTaskGuideContentBullets = [
    "hvad “Har I lavet en aftale?”-opgaven går ud på",
    "hvordan du besvarer opgaven",
];

export const customerConversationGuideContentBullets = [
    "hvad du bør snakke med kunden om",
    "hvordan du evt. kan gøre brug af store redskaber",
    "hvordan du håndterer evt. bortkørsel af haveaffald",
    "hvordan du evt. kan gøre brug af en medhjælper",
    "hvordan du håndterer evt. udlæg for kunden",
];

export const registrationTaskGuideContentBullets = [
    "hvad “Hvordan gik dit besøg?”-opgaven går ud på",
    "hvordan du besvarer opgaven",
    "hvad du gør, hvis der evt. er ændringer til jeres aftale",
    "hvad du gør, hvis opgaven ikke bliver til noget alligevel",
];

export const registrationGuideContentBullets = [
    "hvordan du registrerer tid",
    "hvordan du kan opdele din tidsregistrering",
    "hvad der er effektiv arbejdstid",
];

export const afterRegistrationGuideContentBullets = [
    "hvordan vi står for opkrævning",
    "hvordan du får udbetalt dit honorar",
    "hvordan du får mest ud af konceptet",
];

export const afterClosedTaskGuideContentBullets = [
    "hvad der sker, når du lukker en opgave",
    "hvorfor og hvordan vi vurderer vores samarbejde",
    "hvilke fordele du får, når vi har et godt samarbejde",
    "hvad vi gør, hvis samarbejdet ikke fungerer godt",
];

export const complaintGuideContentBullets = [
    "hvad der sker, når du får en reklamation",
    "hvordan vi hjælper dig",
    "hvordan du kan løse en reklamation",
    "hvad der sker, hvis der ikke kan findes en løsning",
];
