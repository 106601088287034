import { useCallback } from "react";

import firebase from "firebase/app";

import { useSnackbar } from "../../components/snackbar";
import { useBlockingProgress } from "../../components/blocking-progress";
import { BankAccount } from "../personal-information.model";

const requestPayout: (data: RequestGardenerPayoutData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("GardenerPayout-request2");

    export interface RequestGardenerPayoutData {
        bankAccount: BankAccount;
    }

export const useRequestPayoutAction = () => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const action = useCallback((data: RequestGardenerPayoutData) => {
        block();

        requestPayout(data)
            .then(() => {
                openSnackbar("success", "Dit honorar er sat til udbetaling");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Dit honorar er ikke blevet sat til udbetaling.");
                unblock();
            });
    }, [openSnackbar, block, unblock]);

    return action;
};
