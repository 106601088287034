import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum CustomerConversationGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
    Question5VerticalButtons = "Question5VerticalButtons",
    Question6VerticalButtons = "Question6VerticalButtons",
}

export type CustomerConversationGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useCustomerConversationGuideDialog = (onConfirm: FormProps<CustomerConversationGuideForm>["onSubmit"]) => {
    const form: CustomerConversationGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        questionPages.push({
            type: "page",
            title: "Hvad bør du snakke med kunden om?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du har sagt ja tak til en opgave, skal du ringe til kunden for at planlægge jeres besøg.<br />
                            <br />
                            I bør desuden gå opgaven igennem mundtligt for at undgå misforståelser, da opgavebeskrivelsen blot er vejledende. Hvad ønsker kunden f.eks. der skal ske med evt. haveaffald?<br />
                            <br />
                            Det er også altid en god ide at tale med kunden om budgettet for opgaven. Hvis du bliver hurtigt færdig, skal du så stoppe eller fortsætte med noget andet? Omvendt, hvis opgaven tager længere tid end forventet, skal du så blive færdig med opgaven eller nå hvad du kan?
                        `,
                    },
                    {
                        id: CustomerConversationGuideFormIds.Question1VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad er formålet med samtalen?",
                        answerTexts: [
                            "At planlægge et besøg",
                            "At forventningsafstemme",
                            "At planlægge et besøg og forventningsafstemme",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [2],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Mulighed for besigtigelse",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Nogle gange efterspørger kunderne en besigtigelse. Det kan også være, at du selv vurderer, at det er en god ide.<br />
                            <br />
                            Du bestemmer helt selv, om du vil tilbyde en besigtigelse. Du kan dog som udgangspunkt ikke få betaling for det.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Ofte er det muligt at slå besigtigelsen sammen med udførsel af opgaven, så du slipper for at køre en ekstra gang.
                        `
                    },
                    {
                        id: CustomerConversationGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Behøver du udføre besigtigelser?",
                        answerTexts: [
                            "Nej, jeg bestemmer selv",
                            "Ja",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Brug af redskaber",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Brugen af redskaber har indflydelse på kundens timepris. Det er derfor en god ide at tale med kunden om hvilke redskaber der skal bruges til opgaven og hvem der stiller dem til rådighed.<br />
                            <br />
                            Typisk bruges enten taksten “kundens redskaber” eller “havemandens redskaber”, men I kan også aftale at bruge “havemandens store redskaber” i særlige tilfælde.<br />
                            <br />
                            “Havemandens store redskaber” er beregnet til de tilfælde, hvor det er nødvendigt for at løse opgaven eller opgaven kan løses hurtigere med særlige redskaber.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Du bør kun bruge taksten store redskaber, hvis du har aftalt det med kunden på forhånd og du har sikret dig, at kunden er indforstået med den høje timepris.
                        `,
                    },
                    {
                        id: CustomerConversationGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvornår kan jeg bruge taksten “havemandens store redskaber”?",
                        answerTexts: [
                            "Når jeg bruger min havetraktor",
                            "Når jeg har aftalt det med kunden på forhånd",
                            "Når jeg bruger min motorsav",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Bortkørsel af haveaffald",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Hvis kunden ønsker du skal køre haveaffaldet bort, anses kørsel til og aflæsning ved nærmeste genbrugsplads også for at være en del af opgaven.<br />
                            <br />
                            Kørsel med almindelig trailer takseres typisk til “havemandens redskaber”. Bruges der en stor trailer for at køre færre læs, kan I aftale at bruge taksten “havemandens store redskaber”.
                        `,
                    },
                    {
                        id: CustomerConversationGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad er en del af opgaven ved bortkørsel af haveaffald?",
                        answerTexts: [
                            "Kørsel til og aflæsning ved nærmeste genbrugsplads",
                            "Kørsel til og aflæsning ved vilkårlig genbrugsplads",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Brug af medhjælper",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Typisk løser havemændene opgaverne alene, men I kan også aftale, at du medbringer en medhjælper i særlige tilfælde.<br />
                            <br />
                            Det er vigtigt at gøre kunden opmærksom på, at de vil skulle betale for begge havemænds arbejdstid, da det ellers kan give anledning til reklamationer.<br />
                            <br />
                            Kunderne oplever det desuden som ubehageligt, hvis du først spørger om du må medbringe en medhjælper, når I begge er mødt op hos kunden.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Du bør kun medbringe en medhjælper, hvis du har aftalt det med kunden på forhånd og du har sikret dig, at kunden er indforstået med den højere pris.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Ved bortkørsel af haveaffald forventer kunden ikke, at skulle betale for medhjælperens tid ved kørsel.
                        `,
                    },
                    {
                        id: CustomerConversationGuideFormIds.Question5VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvornår kan jeg medbringe en medhjælper?",
                        answerTexts: [
                            "Når kunden accepterer det i døren",
                            "Når jeg har aftalt det med kunden på forhånd",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Håndtering af udlæg",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Nogle gange skal der indkøbes noget til en opgave, f.eks. byggematerialer, planter/frø eller andet.<br />
                            <br />
                            Typisk vil kunden selv stå for det, men det kan også ske, at kunden beder dig om at købe det og tage det med. Det er helt op til dig, om du vil gå med til at lægge ud for kunden.<br />
                            <br />
                            Udlæg er ikke en del af Go Go Gardens koncept, så dem bør I afregne direkte imellem jer, f.eks. med MobilePay. Du må ikke have fortjeneste på udlæg.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Udlæg er det eneste tilfælde, hvor du må opkræve kunder uden om platformen.
                        `,
                    },
                    {
                        id: CustomerConversationGuideFormIds.Question6VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Skal jeg altid lægge ud, hvis kunden beder mig om det?",
                        answerTexts: [
                            "Nej, det bestemmer jeg selv",
                            "Ja",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide og er nu klædt på til din samtale med kunden.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
