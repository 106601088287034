import { useCallback } from "react";

import { Container } from "@material-ui/core";

import { ContentSection } from "../application/ContentSection";
import { useTranslation } from "../internationalisation/translation.hook";
import { TitleHeader } from "../components/page/TitleHeader";
import { useOnboardingGuideDialog } from "./OnboardingGuideDialog";
import { useNewTaskGuideDialog } from "./NewTaskGuide";
import { Section } from "../components/section/Section";
import { useAppointmentTaskGuideDialog } from "./AppointmentTaskGuide";
import { useCustomerConversationGuideDialog } from "./CustomerConversationGuide";
import { useRegistrationTaskGuideDialog } from "./RegistrationTaskGuide";
import { useRegistrationGuideDialog } from "./RegistrationGuide";
import { useAfterRegistrationGuideDialog } from "./AfterRegistrationGuide";
import { useAfterCloseTaskGuideDialog } from "./AfterCloseTaskGuide";
import { GuideCard } from "./GuideCard";
import { UnorderedList } from "../components/unordered-list/UnorderedList";
import { useComplaintGuideDialog } from "./ComplaintGuide";
import { afterClosedTaskGuideContentBullets, afterRegistrationGuideContentBullets, appointmentTaskGuideContentBullets, complaintGuideContentBullets, customerConversationGuideContentBullets, newTaskGuideContentBullets, onboardingGuideContentBullets, registrationGuideContentBullets, registrationTaskGuideContentBullets } from "./TutorialPage";
import { TaskResponseData } from "../task/task-response-action.hook";
import { GardenerDocument, Guide } from "../gardener/gardener.model";

export interface GuidesPageProps {
    gardener: GardenerDocument;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const GuidesPage = (props: GuidesPageProps) => {
    const { gardener, taskResponseAction } = props;

    const { t } = useTranslation();

    const markLookupGuideCompleted = useCallback((guide: Guide) => {
        if ( gardener.tutorial.completedGuides.includes(guide) ) return;

        taskResponseAction({ action: "mark guide completed", mode: "lookup", guide });
    }, [taskResponseAction, gardener]);

    const markOnboardingGuideCompleted = useCallback(() => markLookupGuideCompleted("onboarding"), [markLookupGuideCompleted]);
    const startOnboardingGuide = useOnboardingGuideDialog("look up", markOnboardingGuideCompleted);

    const markNewTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("newTask"), [markLookupGuideCompleted]);
    const startNewTaskGuide = useNewTaskGuideDialog(markNewTaskGuideCompleted);

    const markAppointmentTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("appointmentTask"), [markLookupGuideCompleted]);
    const startAppointmentTaskGuide = useAppointmentTaskGuideDialog(markAppointmentTaskGuideCompleted);

    const markCustomerConversationGuideCompleted = useCallback(() => markLookupGuideCompleted("customerConversation"), [markLookupGuideCompleted]);
    const startCustomerConversationGuide = useCustomerConversationGuideDialog(markCustomerConversationGuideCompleted);

    const markRegistrationTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("registrationTask"), [markLookupGuideCompleted]);
    const startRegistrationTaskGuide = useRegistrationTaskGuideDialog(markRegistrationTaskGuideCompleted);

    const markRegistrationGuideCompleted = useCallback(() => markLookupGuideCompleted("registration"), [markLookupGuideCompleted]);
    const startRegistrationGuide = useRegistrationGuideDialog(markRegistrationGuideCompleted);

    const markAfterRegistrationGuideCompleted = useCallback(() => markLookupGuideCompleted("afterRegistration"), [markLookupGuideCompleted]);
    const startAfterRegistrationGuide = useAfterRegistrationGuideDialog("look up", markAfterRegistrationGuideCompleted);

    const markAfterCloseTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("afterClosedTask"), [markLookupGuideCompleted]);
    const startAfterCloseTaskGuide = useAfterCloseTaskGuideDialog(markAfterCloseTaskGuideCompleted);

    const markComplaintGuideCompleted = useCallback(() => markLookupGuideCompleted("complaint"), [markLookupGuideCompleted]);
    const startComplaintGuide = useComplaintGuideDialog(markComplaintGuideCompleted);

    return (
        <>
            <TitleHeader>{t("TopBar: guides-link")}</TitleHeader>

            <ContentSection>

                <Container maxWidth="md" disableGutters>

                    <Section>
                        <GuideCard title="Generelt om konceptet" startGuide={startOnboardingGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {onboardingGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                    <Section>
                        <GuideCard title="Sådan besvarer du “Ny opgave”" startGuide={startNewTaskGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {newTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                    <Section>
                        <GuideCard title="Sådan besvarer du “Har I lavet en aftale?”" startGuide={startAppointmentTaskGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {appointmentTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                    <Section>
                        <GuideCard title="Samtalen med kunden" startGuide={startCustomerConversationGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {customerConversationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                    <Section>
                        <GuideCard title="Sådan besvarer du “Hvordan gik dit besøg?”" startGuide={startRegistrationTaskGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {registrationTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                    <Section>
                        <GuideCard title="Sådan registrerer du tid" startGuide={startRegistrationGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {registrationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                    <Section>
                        <GuideCard title="Hvad sker der, når du registrerer tid?" startGuide={startAfterRegistrationGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {afterRegistrationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                    <Section>
                        <GuideCard title="Hvad sker der, hvis du lukker en opgave?" startGuide={startAfterCloseTaskGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {afterClosedTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                    <Section>
                        <GuideCard title="Hvad sker der, hvis du får en reklamation?" startGuide={startComplaintGuide}>
                            <p>Denne guide handler om:</p>
                            <UnorderedList>
                                {complaintGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                            </UnorderedList>
                        </GuideCard>
                    </Section>

                </Container>

            </ContentSection>
        </>
    );
};
