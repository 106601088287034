import moment from "moment";

import { NewTaskDetailPage } from "./NewTaskDetailPage";
import { OfferResponseTaskDocument } from "../../task/task.model";
import { NewTaskResponseData, useNewTaskResponseAction } from "../../task/new-task-response-action.hook";
import { useTaskResponseAction } from "../../task/task-response-action.hook";
import { useUser } from "../../user/UserProvider";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export interface IntegratedNewTaskDetailPageProps {
    title: JSX.Element;
    task: OfferResponseTaskDocument;
}

export const IntegratedNewTaskDetailPage = (props: IntegratedNewTaskDetailPageProps) => {
    const { title, task } = props;

    const gardener = useUser();

    const history = useHistory();
    const redirect = useCallback((action: NewTaskResponseData["action"]) => {
        switch ( action ) {
            case "decline": return history.push(`/overview/redirects/new-task/${task.gardeningTaskId}?status=performed`);
            case "accept": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=active`);
        }
    }, [history, task]);

    const newTaskResponseAction = useNewTaskResponseAction(redirect);
    const taskResponseAction = useTaskResponseAction();
    
    const now = moment().toISOString(true);

    return (
        <>
            {title}

            <NewTaskDetailPage
                gardener={gardener}
                task={task}
                now={now}
                newTaskResponseAction={newTaskResponseAction}
                taskResponseAction={taskResponseAction}
            />

        </>
    );
};
