import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum AfterCloseTaskGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
}

export type AfterCloseTaskGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useAfterCloseTaskGuideDialog = (onConfirm: FormProps<AfterCloseTaskGuideForm>["onSubmit"]) => {
    const form: AfterCloseTaskGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        questionPages.push({
            type: "page",
            title: "Hvad sker der, når du lukker en opgave?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du lukker en opgave, vil vi forsøge at finde en ny havemand, hvis der er behov for det. Er der ikke det, så informerer vi bare kunden om, at opgaven er blevet lukket.<br />
                            <br />
                            Det er selvfølgelig ærgerligt, når en opgave ikke bliver til noget, men vi ved godt, at det ikke er alle opgaver, der kan lykkes.<br />
                            <br />
                            Vores system holder automatisk styr på, hvor mange af dine opgaver, der lykkes, og hvor mange der ikke lykkes. Vi vil gerne fortælle dig lidt mere om, hvad vi bruger det til.
                        `,
                    },
                    {
                        id: AfterCloseTaskGuideFormIds.Question1VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan følger Go Go Garden med i, hvordan jeg klarer mig?",
                        answerTexts: [
                            "Det sker slet ikke",
                            "Det sker manuelt",
                            "Det sker automatisk",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [2],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvorfor følger vi med i, hvordan du klarer dig?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Vi har skabt et koncept, hvor kunder forventer, at få en god og pålidelig havemand og hvor havemænd nemt kan få opgaver.<br />
                            <br />
                            Når vi starter samarbejder op med nye havemænd over hele Danmark, vil der være mange havemænd, som er gode for konceptet, mens andre vil svække konceptet.<br />
                            <br />
                            Derfor har vi udviklet et system, som vurderer samarbejdet med den enkelte havemand. Det er nødvendigt for at konceptet kan fungere godt og for at vi kan tilgodese de gode havemænd.
                        `,
                    },
                    {
                        id: AfterCloseTaskGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvorfor følger Go Go Garden med i, hvordan jeg klarer mig?",
                        answerTexts: [
                            "For at konceptet kan fungere og for at tilgodese de gode havemænd",
                            "For at være efter mig",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan fungerer systemet",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Hver gang du løser en opgave, får du mulighed for at få tilbudt endnu flere opgaver. Omvendt vil du blive tilbudt færre nye opgaver, når du lukker en opgave, efter du har sagt ja tak.<br />
                            <br />
                            Når du løser de fleste af dine opgaver, behøver du ikke bekymre dig om, at nogle af dem ikke lykkes. Du vil desuden blive tilbudt flere opgaver og blive tilbudt opgaver før andre havemænd.<br />
                            <br />
                            Hvis du kun løser få af dine opgaver, risikerer du at løbe tør for opgaver. I det tilfælde vil vi tage en samtale med dig.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Vi vurderer kun vores samarbejde ud fra de opgaver, du siger ja tak til. Du skal derfor ikke være nervøs for at sige nej tak til opgaver, som ikke er noget for dig.
                        `,
                    },
                    {
                        id: AfterCloseTaskGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Skal jeg bekymre mig, når jeg lukker en opgave?",
                        answerTexts: [
                            "Nej. Det gør ikke noget, hvis jeg løser de fleste opgaver",
                            "Ja. Jeg bør aldrig lukke en opgave",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad sker der, hvis du løber tør for opgaver?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Er det første gang du løber tør for opgaver, vil vi tage en samtale med dig. Her vil vi prøve at spore os ind på, hvorfor du er løbet tør og hvad du i fremtiden kan gøre for at undgå det.<br />
                            <br />
                            Hvis både du og Go Go Garden ønsker det, kan vi vælge at give samarbejdet en chance til. I det tilfælde får du mulighed for at sige ja tak til 3 nye opgaver.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Løber du tør for opgaver flere gange, er det ikke sikkert, at Go Go Garden vil give samarbejdet flere chancer.
                        `,
                    },
                    {
                        id: AfterCloseTaskGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Ophører samarbejdet automatisk, hvis jeg gør det dårligt?",
                        answerTexts: [
                            "Ja, det sker helt af sig selv",
                            "Nej, Go Go Garden vil tage en samtale med mig",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide og er nu klædt på ift. hvad der sker, når du lukker en opgave og hvordan vi vurderer vores samarbejde.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
