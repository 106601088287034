import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum AfterRegistrationGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
    Question5VerticalButtons = "Question5VerticalButtons",
    Question6VerticalButtons = "Question6VerticalButtons",
}

export type AfterRegistrationGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useAfterRegistrationGuideDialog = (mode: "tutorial" | "look up", onConfirm: FormProps<AfterRegistrationGuideForm>["onSubmit"]) => {
    const form: AfterRegistrationGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        questionPages.push({
            type: "page",
            title: "Hvad sker der, når du har registreret tid?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du har registreret tid, bliver din kunde automatisk faktureret på dine vegne og Go Go Garden går i gang med at opkræve betaling fra kunden.<br />
                            <br />
                            Når kunden har betalt fakturaen, kan du få udbetalt dit honorar.<br />
                            <br />
                            Hvis kunden mod forventning er utilfreds med din faktura, kan de oprette en reklamation. Se evt. vores guide til reklamationer.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Opdager du, at du har registreret forkert, så kontakt os hurtigst muligt via “Bed om hjælp”, så vi kan rette det.
                        `,
                    },
                    {
                        id: AfterRegistrationGuideFormIds.Question1VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvornår kan jeg få udbetalt mit honorar?",
                        answerTexts: [
                            "Når jeg har løst kundens opgave",
                            "Når kunden har betalt sin faktura",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Opkrævning",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Go Go Garden står for opkrævning af betaling fra kunden, så det behøver du ikke tænke på. Kunderne har 8 dage til at betale, medmindre faktura tilsendes med posten eller EAN.<br />
                            <br />
                            Betaler kunden ikke til tiden, så kontakter vi dem via email, sms, brev og telefonopkald, udsteder rykkere og i sidste instans sender dem til udenretslig inkasso.<br />
                            <br />
                            Langt de fleste kunder betaler naturligvis deres regninger, så manglende betaling er sjældent et problem.
                        `,
                    },
                    {
                        id: AfterRegistrationGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvem står for opkrævning af betaling?",
                        answerTexts: [
                            "Det gør jeg selv",
                            "Det gør Go Go Garden",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Udbetaling af honorar",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når din kunde har betalt sin faktura, kan du få udbetalt dit honorar. Betaler kunden kun en del af fakturaen, fordeler vi betalingen sådan, at du får dit honorar før Go Go Garden får sit.<br />
                            <br />
                            Du kan følge med i om kunder har betalt ved at gå ind på “Tidsregistreringer” eller ved at tjekke din saldo på din profil.<br />
                            <br />
                            Når du gerne vil have udbetalt dit honorar, kan du gå ind på din profil og bruge knappen “Udbetal honorar”.
                        `,
                    },
                    {
                        id: AfterRegistrationGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan får jeg udbetalt mit honorar?",
                        answerTexts: [
                            "Jeg bruger “Udbetal honorar” på min profil",
                            "Det sker automatisk hver måned",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan får du mere ud af hver opgave",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Mange kunder bestiller kun hjælp én gang, men kan ofte have glæde af mere. Det kan være en god ide at spørge kunden, om de vil fortsætte, når man har hjulpet dem første gang.<br />
                            <br />
                            Det er særligt en fordel at have faste kunder i lavsæsonen, hvor vi ikke kan skaffe lige så mange nye opgaver til dig.<br />
                            <br />
                            Mange havemænd kan desuden godt lide at hjælpe den samme kunde flere gange, både fordi det er hyggeligt og fordi det er nemmere at hjælpe kunder, man allerede kender.
                        `,
                    },
                    {
                        id: AfterRegistrationGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan kan jeg få mere ud af hver opgave?",
                        answerTexts: [
                            "Spørge kunden om jeg skal hjælpe dem igen",
                            "Vente på at kunden beder mig hjælpe dem igen",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan får du flere opgaver",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du starter som havemand, giver vi dig mulighed for at sige ja tak til 3 opgaver. Hver gang du løser en opgave, giver vi dig mulighed for at sige ja tak til endnu flere opgaver.<br />
                            <br />
                            Hvis vi har et godt samarbejde, vil vi desuden tilbyde dig opgaver før andre havemænd i dit område.<br />
                            <br />
                            Jo bedre vores samarbejde er, jo flere opgaver kan du altså få.
                        `,
                    },
                    {
                        id: AfterRegistrationGuideFormIds.Question5VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan får jeg flere opgaver?",
                        answerTexts: [
                            "Ved ikke at sige nej tak til for mange opgaver",
                            "Ved at løse de opgaver, jeg siger ja tak til",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, når du skal hjælpe en kunde igen?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Beder kunden dig hjælpe dem igen, kan du planlægge et nyt besøg hos dem.<br />
                            <br />
                            Det gør du ved at gå ind på “Kunder” og finde kunden under tidligere eller aktuelle kunder. Her kan du bruge knappen “Kunden har brug for hjælp igen”.<br />
                            <br />
                            Du vil ikke se knappen, hvis du allerede har en opgave i gang hos kunden. I det tilfælde kan du først planlægge et nyt besøg, når du har afsluttet det første.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Hvis du ikke kan finde kunden på din liste, kan du kontakte os via “Bed om hjælp”.
                        `,
                    },
                    {
                        id: AfterRegistrationGuideFormIds.Question6VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan planlægger jeg et nyt besøg hos kunden?",
                        answerTexts: [
                            "Jeg går ind på kunden og bruger knappen “Kunden har brug for hjælp igen”",
                            "Jeg beder kunden om at bestille igen",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = mode === "tutorial" ? {
            type: "page",
            title: "Du er nu færdig med vores onboarding",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har været igennem det vigtigste og er nu færdig med disse guides for denne gang.<br />
                    <br />
                    Du kan finde alle de guides, du har været igennem og andre under menupunktet “Guides” i topbaren.<br />
                    <br />
                    Hvis du i fremtiden støder på noget, som du ikke har været igennem endnu, vil vi også guide dig igennem det til den tid.
                `,
            },
            nextText: "Afslut guide",
        } : {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide og er nu klædt på ift. hvad der sker efter fakturering og hvordan du får mest ud af konceptet.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, [mode]);

    return useFormDialog(form, onConfirm);
};
