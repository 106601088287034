import { Container, makeStyles } from "@material-ui/core";

import { ContentSection } from "../application/ContentSection";
import { TitleHeader } from "../components/page/TitleHeader";
import { GardenerDocument } from "./gardener.model";
import { PreferencesCard } from "./PreferencesCard";
import { GardenerPausedSwitch } from "./GardenerPausedSwitch";
import { GardenerCard } from "./GardenerCard";
import { TaskResponseData } from "../task/task-response-action.hook";
import { CommissionPayoutCard } from "./payout/CommissionPayoutCard";
import { GardenerBalanceDocument } from "./payout/gardener-balance.model";
import { GardenerPayoutDocument } from "./payout/gardener-payout.model";
import { PersonalInformationDocument } from "./personal-information.model";
import { RequestGardenerPayoutData } from "./payout/request-payout.hook";

const useStyles = makeStyles({
    container: {
        "& > *": {
            marginBottom: "32px",
        },
    
        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

interface ProfilePageProps {
    gardener: GardenerDocument;
    gardenerBalance: GardenerBalanceDocument | null;
    gardenerPayout: GardenerPayoutDocument | null;
    personalInformation: PersonalInformationDocument | null;
    taskResponseAction: (data: TaskResponseData | string) => void;
    requestPayoutAction: (data: RequestGardenerPayoutData) => void;
}

export const ProfilePage = (props: ProfilePageProps) => {
    const classes = useStyles();

    const { gardener, gardenerBalance, gardenerPayout, personalInformation, taskResponseAction, requestPayoutAction } = props;

    return (
        <>
            <TitleHeader>Profil</TitleHeader>

            <ContentSection>
                <Container maxWidth="sm" disableGutters className={classes.container}>

                    <GardenerPausedSwitch gardener={gardener} />
                    <GardenerCard gardener={gardener} taskResponseAction={taskResponseAction} />

                    {gardenerBalance && personalInformation ? (
                        <CommissionPayoutCard
                            payoutInProgress={gardenerBalance.inProgress}
                            amountScheduled={gardenerPayout?.amount ?? null}
                            balance={gardenerBalance.balance}
                            bankAccount={personalInformation.bankAccount}
                            onRequestPayout={requestPayoutAction}
                        />
                    ) : null}

                    <PreferencesCard gardener={gardener} />

                </Container>
            </ContentSection>
        </>
    )
};
