import { PropsWithChildren } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { Topbar } from "./Topbar";
import { SideNavigation } from "./SideNavigation";
import { useUser } from "../user/UserProvider";
import { StatusBanner } from "../gardener/StatusBanner";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },

    container: {
        flexGrow: 1,
        display: "flex",
    },

    main: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
    },
});

interface LayoutProps {
    hideSidebar?: boolean;
    hideStatusBanner?: boolean;
}

export const Layout = (props: PropsWithChildren<LayoutProps>) => {
    const classes = useStyles();
    const { hideSidebar, hideStatusBanner, children } = props;

    const gardener = useUser();

    const paused = gardener.protections.includes("manual-gardener") || gardener.protections.includes("no-reply") || gardener.protections.includes("picky-gardener") || gardener.protections.includes("process");
    const shouldShowStatusBanner = !hideStatusBanner && (gardener.status === "terminated" || paused);

    return (
        <div className={classes.root}>
            <header>
                <Topbar />
            </header>

            <div className={classes.container}>
                {!hideSidebar ? <SideNavigation /> : null}

                <main className={classes.main}>

                    <Collapse appear={false} in={shouldShowStatusBanner}>
                        <StatusBanner enabled={shouldShowStatusBanner} />
                    </Collapse>

                    {children}
                </main>
            </div>
        </div>
    );
};
