import { FormItem } from "../../framework/core/form-item.model";
import { FormItemSupport } from "../../framework/core/form-item.support";
import { FormController } from "../../framework/core/form.controller";

export interface PageFormItem<NextItem extends FormItem> {
    type: "page",
    title: string;
    item: Exclude<NextItem, { type: "page" }>,
    backText?: string;
    nextText?: string;
}

export class PageFormItemSupport implements FormItemSupport<PageFormItem<FormItem>> {

    supportedType = "page" as const;

    updateChildren(item: PageFormItem<FormItem>, updateChildItem: (item: FormItem) => PageFormItem<FormItem>["item"]): PageFormItem<FormItem> {
        return { ...item, item: updateChildItem(item.item) };
    };

    markDirty = undefined;

    getAllChildren(controller: FormController, item: PageFormItem<FormItem>) {
        return [item.item];
    }

    getActiveChildren(controller: FormController, item: PageFormItem<FormItem>) {
        return [item.item];
    }

    blocksParent(): boolean {
        return false;
    }
}
