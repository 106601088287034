import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum AppointmentTaskGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
    Question5VerticalButtons = "Question5VerticalButtons",
}

export type AppointmentTaskGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useAppointmentTaskGuideDialog = (onConfirm: FormProps<AppointmentTaskGuideForm>["onSubmit"]) => {
    const form: AppointmentTaskGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        questionPages.push({
            type: "page",
            title: "Hvad går “Har I lavet en aftale?” ud på?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            “Har I lavet en aftale?”-opgaverne minder dig om, at du skal ringe til kunden for at planlægge et besøg.<br />
                            <br />
                            Når du siger ja tak til en ny opgave, forventes det, at du besvarer opgaven samme dag, medmindre du har sagt ja tak sent om aftenen. Du kan se svarfristen på opgaven.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Hvis du ikke besvarer en opgave til tiden, vil du automatisk blive sat på pause, indtil du besvarer den.
                        `,
                    },
                    {
                        id: AppointmentTaskGuideFormIds.Question1VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvornår skal jeg besvare opgaven?",
                        answerTexts: [
                            "Inden for svarfristen, som jeg kan se på opgaven",
                            "Inden for en uge",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, når I har planlagt et besøg?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når I har planlagt et besøg, så bruger du knappen “Vi har planlagt et besøg”. Her kan du angive dato og en kort beskrivelse til dig selv.<br />
                            <br />
                            Vi hjælper dig med at holde styr på dine planlagte besøg og minder dig også om dem med en sms dagen inden dit besøg.
                        `,
                    },
                    {
                        id: AppointmentTaskGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, når I har planlagt et besøg?",
                        answerTexts: [
                            "Vi har planlagt et besøg",
                            "Jeg har ringet til kunden uden held",
                            "Kunden kan ikke snakke lige nu, så vi kan ikke planlægge et besøg endnu",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, hvis du ikke får fat i kunden?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Det er ikke altid, du får fat i kunden i første opkald. I det tilfælde besvarer du bare opgaven og bruger knappen “Jeg har ringet til kunden uden held”. Så bliver opgaven udskudt til næste dag.<br />
                            <br />
                            Vi anbefaler, at du ringer 1 gang om dagen over tre dage, men du bestemmer selv, hvor mange gange du vil forsøge.<br />
                            <br />
                            Får du heller ikke fat i kunden tredje gang du besvarer opgaven, bliver opgaven lukket. Vi kontakter kunden og vil så vidt muligt prioritere dig, hvis kunden stadig er interesseret i at få hjælp.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Vi anbefaler, at du efter første opkald også lægger en besked eller sender en sms, hvis ikke du får fat i kunden.
                        `,
                    },
                    {
                        id: AppointmentTaskGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, hvis du ikke får fat i kunden?",
                        answerTexts: [
                            "Vi har planlagt et besøg",
                            "Jeg har ringet til kunden uden held",
                            "Kunden kan ikke snakke lige nu, så vi kan ikke planlægge et besøg endnu",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du hvis I ikke kan planlægge et besøg endnu?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Af og til fanger man kunden på et uheldigt tidspunkt, f.eks. under ferie, sygdom eller mens de er midt i noget andet.<br />
                            <br />
                            I det tilfælde kan I bare aftale at tales ved senere og så bruger du knappen “Kunden kan ikke snakke lige nu, så vi kan ikke planlægge et besøg endnu” for at udskyde opgaven.<br />
                            <br />
                            Hvis der går over en måned, før I kan tales ved, anser vi ikke opgaven for at være aktuel og lukker den, så du slipper for at bruge mere tid på den. Vi vil så kontakte kunden og vil så vidt muligt prioritere dig, hvis de får brug for hjælp igen.
                        `,
                    },
                    {
                        id: AppointmentTaskGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, hvis I ikke kan planlægge et besøg endnu?",
                        answerTexts: [
                            "Vi har planlagt et besøg",
                            "Jeg har ringet til kunden uden held",
                            "Kunden kan ikke snakke lige nu, så vi kan ikke planlægge et besøg endnu",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [2],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad gør du, hvis opgaven ikke bliver til noget?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du snakker med kunden, kan det være, at du opdager, at du ikke kan løse den alligevel eller at kunden ikke har brug for hjælp længere. Det er rigtig ærgerligt, men det hænder.<br />
                            <br />
                            I det tilfælde bruger du knappen “Opgaven skal lukkes”. Når du bliver spurgt, om vi skal finde en anden havemand til opgaven, svarer du ja, hvis du tror, kunden stadig ønsker hjælp.
                        `,
                    },
                    {
                        id: AppointmentTaskGuideFormIds.Question5VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvilken knap bruger du, hvis opgaven ikke bliver til noget?",
                        answerTexts: [
                            "Vi har planlagt et besøg",
                            "Jeg har ringet til kunden uden held",
                            "Kunden kan ikke snakke lige nu, så vi kan ikke planlægge et besøg endnu",
                            "Opgaven skal lukkes",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [3],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide, og er nu klædt på til at besvare “Har I lavet en aftale?”-opgaver.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
