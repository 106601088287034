import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";

export enum NewTaskGuideFormIds {
    Question1VerticalButtons = "Question1VerticalButtons",
    Question2VerticalButtons = "Question2VerticalButtons",
    Question3VerticalButtons = "Question3VerticalButtons",
    Question4VerticalButtons = "Question4VerticalButtons",
    Question5VerticalButtons = "Question5VerticalButtons",
    Question6VerticalButtons = "Question6VerticalButtons",
}

export type NewTaskGuideForm = GroupFormItem<Array<QuestionPage | ConfirmPage>>

type QuestionPage = PageFormItem<FormItem>;

type ConfirmPage = PageFormItem<InfoFormItem>

export const useNewTaskGuideDialog = (onConfirm: FormProps<NewTaskGuideForm>["onSubmit"]) => {
    const form: NewTaskGuideForm = useMemo(() => {

        const questionPages: QuestionPage[] = [];

        questionPages.push({
            type: "page",
            title: "Hvordan kan du besvare opgaven?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du bliver tilbudt en opgave, kan du enten svare ja tak, hvis den er noget for dig, eller nej tak, hvis den ikke er.<br />
                            <br />
                            Svarer du nej tak, bliver opgaven tilbudt til en anden havemand.<br />
                            <br />
                            Du bestemmer helt selv, hvilke opgaver du vil sige ja tak til, men du bør kun sige ja tak til opgaver, som du tror, du kan løse.
                        `,
                    },
                    {
                        id: NewTaskGuideFormIds.Question1VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvor mange opgaver skal jeg sige ja tak til?",
                        answerTexts: [
                            "Alle opgaver jeg får tilbudt",
                            "Jeg bestemmer helt selv",
                            "Mindst 50% af opgaverne",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Informationer på opgaven",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Du vil få præsenteret opgaven, som kunden har beskrevet den:
                            <ul>
                                <li>omtrentlig lokation</li>
                                <li>hvor lang tid kunden, tror opgaven vil  tage</li>
                                <li>om kunden kan stille redskaber til rådighed</li>
                                <li>om kunden har brug for hjælp én eller flere gange</li>
                                <li>hvad opgaven drejer sig om</li>
                                <li>beskrivelse af opgaven</li>
                            </ul>
                        `,
                    },
                    {
                        id: NewTaskGuideFormIds.Question2VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvem har beskrevet opgaven?",
                        answerTexts: [
                            "Kunden selv",
                            "Go Go Garden",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan forholder du dig til opgaven",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når kunden bestiller, beskriver de opgaven, så godt de kan. Men de ved ikke altid, hvilke redskaber der er behov for eller hvor lang tid det tager at løse opgaven.<br />
                            <br />
                            Det kan f.eks. være, at kunden under- eller overvurderer hvor lang tid opgaven vil tage. <br />
                            <br />
                            Derfor skal du ikke tænke på oplysningerne som krav, og det bør ikke afholde dig fra at sige ja tak, hvis opgaven ellers lyder som noget for dig.
                        `,
                    },
                    {
                        id: NewTaskGuideFormIds.Question3VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan skal jeg tænke på oplysningerne om opgaven?",
                        answerTexts: [
                            "De er blot vejledende",
                            "Det er krav fra kunden"
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [0],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvornår skal kunden have hjælp?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Nogle kunder bestiller i god tid, men de fleste ønsker hjælp hurtigst muligt.<br />
                            <br />
                            Du bør derfor tænke over, om du kan hjælpe kunden inden for rimelig tid. Hvis ikke, bør du sige nej tak til opgaven.
                        `,
                    },
                    {
                        id: NewTaskGuideFormIds.Question4VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvor hurtigt skal jeg kunne hjælpe kunden?",
                        answerTexts: [
                            "Samme dag",
                            "Inden for rimelig tid",
                            "Inden for en måned",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Hvad forventes der, hvis du siger ja tak?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Hvis du accepterer opgaven, bliver det dit ansvar at sikre, at kunden får løst opgaven og får en god oplevelse.<br />
                            <br />
                            Når du siger ja tak, vil du blive bedt om at ringe til kunden og planlægge et besøg for at løse opgaven.<br />
                            <br />
                            Når du har løst opgaven, registrerer du tid og kunden bliver automatisk faktureret på dine vegne.
                        `,
                    },
                    {
                        id: NewTaskGuideFormIds.Question5VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvad forpligter jeg mig til, hvis jeg siger ja tak?",
                        answerTexts: [
                            "Udføre opgaven, fakturere kunden og opkræve betaling",
                            "Planlægge besøg, udføre opgaven og registrere tid",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });

        questionPages.push({
            type: "page",
            title: "Sådan får du flere opgaver",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Når du starter som havemand, giver vi dig mulighed for at sige ja tak til 3 opgaver. Hver gang du løser en opgave, giver vi dig mulighed for at sige ja tak til endnu flere opgaver.<br />
                            <br />
                            Vi forventer ikke, at 100% af alle opgaver lykkes, fordi meget også afhænger af kunden. Men jo bedre vores samarbejde er, jo flere opgaver kan du få.<br />
                            <br />
                            Hvis du løser de fleste af dine opgaver, vil vi desuden tilbyde dig opgaver før andre havemænd i dit område.
                        `,
                    },
                    {
                        id: NewTaskGuideFormIds.Question6VerticalButtons,
                        type: "vertical-buttons",
                        mode: "regular",
                        label: "Hvordan får jeg flere opgaver?",
                        answerTexts: [
                            "Ved ikke at sige nej tak til for mange opgaver",
                            "Ved at løse de fleste af mine opgaver",
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        validIndexes: [1],
                    },
                ],
            },
        });
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Afslut guide",
            item: {
                type: "info",
                render: "default",
                text: `
                    Du har gennemført denne guide og er nu klædt på til at besvare nye opgaver.
                `,
            },
            nextText: "Afslut guide",
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                ...questionPages.map((page, index) => ({
                    ...page,
                    title: `${index + 1}/${questionPages.length} ${page.title}`,
                })),
                confirmPage,
            ],
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
