import { useCallback } from "react";

import firebase from "firebase/app";

import { useBlockingProgress } from "../components/blocking-progress";
import { useSnackbar } from "../components/snackbar";
import { useTranslation } from "../internationalisation/translation.hook";
import { Task } from "../registration/registration.model";
import { Guide, GuideMode } from "../gardener/gardener.model";

export type TaskResponseData =
    | CreateAppointmentData
    | CreateAdditionalAppointmentData
    | UpdateAppointmentData
    | CancelAppointmentData
    | RejectTaskBecauseGardenerCouldNotFindDateWithCustomerData
    | RejectTaskBecauseGardenerSaysCustomerDoesNotNeedHelpData
    | RejectTaskBecauseGardenerSaysCustomerMightNeedHelpData
    | RejectTaskBecauseGardenerCouldNotHelpCustomerData
    | RegisterContactAttemptData
    | PostponeContactData
    | CreateRegistrationData
    | AskForHelpData
    | MarkGuideCompletedData
    | UpdateGardenerData

export interface CreateAppointmentData {
    action: "create appointment";
    taskId: string;
    date: string;
    description: string;
}

export interface CreateAdditionalAppointmentData {
    action: "create additional appointment";
    customerId: string;
    date: string;
    description: string;
}

export interface UpdateAppointmentData {
    action: "update appointment";
    taskId: string;
    hasVisited: boolean;
    date: string;
    description: string;
}

export interface CancelAppointmentData {
    action: "cancel appointment";
    taskId: string;
    hasVisited: boolean;
}

export interface RejectTaskBecauseGardenerCouldNotFindDateWithCustomerData {
    action: "reject task because gardener could not find date with customer",
    taskId: string;
    reason: string,
}

export interface RejectTaskBecauseGardenerSaysCustomerDoesNotNeedHelpData {
    action: "reject task because gardener says customer does not need help",
    taskId: string;
    reason: string,
}

export interface RejectTaskBecauseGardenerSaysCustomerMightNeedHelpData {
    action: "reject task because gardener says customer might need help",
    taskId: string;
    reason: string,
}

export interface RejectTaskBecauseGardenerCouldNotHelpCustomerData {
    action: "reject task because gardener could not help customer",
    taskId: string;
    reason: string,
}

export interface RegisterContactAttemptData {
    action: "register contact attempt",
    taskId: string;
    shouldGiveUp: boolean;
}

export interface PostponeContactData {
    action: "postpone contact",
    taskId: string;
    reason: string,
    date: string,
}

export interface CreateRegistrationData {
    action: "create registration",
    taskId: string;
    tasks: Task[],
}

export interface AskForHelpData {
    action: "ask for help";
    message: string;
}

export interface MarkGuideCompletedData {
    action: "mark guide completed";
    mode: GuideMode,
    guide: Guide;
}

export interface UpdateGardenerData {
    action: "update gardener";
    email: string;
    phoneNumber: string;
    externalAddressId: string;
}

const taskResponse: (data: TaskResponseData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Match-taskResponse2");

export const useTaskResponseAction = (callback?: (action: TaskResponseData["action"]) => void) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useCallback((data: TaskResponseData | string) => {
        if ( typeof data === "string" ) return;

        block();

        taskResponse(data)
            .then((response) => {
                if ( response.data === "has-already-scheduled-appointment" ) {
                    openSnackbar("warning", t("CustomerDetailPage: has-already-scheduled-appointment-warning-message"));
                    unblock();
                    return;
                }

                openSnackbar("success", t("CurrentTasksTab: task-response-success-message"));
                unblock();
                if ( callback ) callback(data.action);
            })
            .catch(() => {
                openSnackbar("error", t("CurrentTasksTab: task-response-error-message"));
                unblock();
            });

    }, [block, unblock, openSnackbar, t, callback]);
};
