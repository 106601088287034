import { useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import moment from "moment";

import { LinearProgress } from "../../application/LinearProgress";
import { useTasksBetweenGardenerAndCustomer } from "../../task/tasks.hook";
import { useUser } from "../../user/UserProvider";
import { AppointmentFollowUpTaskDocument } from "../../task/task.model";
import { determineDaysUntilTaskDeadline } from "../../utility";

const useQuery = () => {
    const location = useLocation();

    return new URLSearchParams(location.search);
};

export const RedirectToAppointmentFollowUpTask = () => {
    const { customerId } = useParams<{ customerId: string }>();

    const query = useQuery();

    const status = (() => {
        switch ( query.get("status") ) {
            case "deleted": return "deleted";
            case "active": return "active";
            case "performed": return "performed";
            default: return "ignore";
        }
    })();

    const date = (() => {
        switch ( query.get("date") ) {
            case "future": return "future";
            default: return "ignore";
        }
    })();

    const gardener = useUser();
    const types = useRef<AppointmentFollowUpTaskDocument["type"][]>(["appointment-follow-up"]).current;
    const tasks = useTasksBetweenGardenerAndCustomer(gardener.id, customerId, types);

    const history = useHistory();
    const goToList = useCallback(() => history.replace("/overview"), [history]);

    useEffect(() => {
        if ( tasks === undefined ) return;

        const task = tasks && tasks.length > 0 ? tasks[0] : null;
        if ( !task && status === "deleted" ) return goToList();
        if ( !task ) return;

        if ( !task.hasBeenPerformed && status === "active" ) return goToList();
        if ( task.hasBeenPerformed && status === "performed" ) return goToList();

        const now = moment().toISOString(true);
        const days = determineDaysUntilTaskDeadline(task, now);

        const isDueInFuture = days > 0;
        if ( isDueInFuture && date === "future" ) return goToList();
    }, [tasks, status, date, goToList]);

    return <LinearProgress />;
};
