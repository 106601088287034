import { GroupFormItem, NoticeFormItem, PageFormItem, SingleChoiceFormItem } from "../../components";

export interface ActionPageIds {
    singleChoiceAction: string;
}

export type ActionPage<Pages extends PageFormItem<any>[]> =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            SingleChoiceFormItem<Pages>,
        ]>
    >;

export const createActionPage = (planVisitPage: PageFormItem<any>, calledCustomerPage: PageFormItem<any> | null, postponeTaskPage: PageFormItem<any>, closeTaskPage: PageFormItem<any>, formIds: ActionPageIds): ActionPage<any[]> => {
    const answerTexts = [];
    answerTexts.push("Vi har planlagt et besøg");
    if ( calledCustomerPage ) answerTexts.push("Jeg har ringet til kunden uden held");
    answerTexts.push("Kunden kan ikke snakke lige nu, så vi kan ikke planlægge et besøg endnu");
    answerTexts.push("Opgaven skal lukkes");

    const answerItems: PageFormItem<any>[] = [];
    answerItems.push(planVisitPage);
    if ( calledCustomerPage ) answerItems.push(calledCustomerPage);
    answerItems.push(postponeTaskPage);
    answerItems.push(closeTaskPage);

    const actionPage: ActionPage<any[]> = {
        type: "page",
        title: "Besvar opgave",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "notice",
                    render: "info",
                    html: "Du har accepteret en opgave. Det forventes, at du ringer til kunden for at planlægge et besøg.",
                },
                {
                    id: formIds.singleChoiceAction,
                    type: "single-choice",
                    mode: "switch page",
                    render: "vertical-buttons",
                    selectedChoiceIndex: null,
                    dirty: false,
                    questionText: "Hvad vil du gerne registrere?",
                    answerTexts,
                    answerItems,
                    destructive: true,
                },
            ],
        },
    };

    return actionPage;
};
