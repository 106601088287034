import { Button, makeStyles } from "@material-ui/core";

import { AccordionList } from "../components/accordion/AccordionList";
import { AccordionListItem } from "../components/accordion/AccordionListItem";
import { UnorderedList } from "../components/unordered-list/UnorderedList";
import { useAfterCloseTaskGuideDialog } from "./AfterCloseTaskGuide";
import { useRegistrationTaskGuideDialog } from "./RegistrationTaskGuide";
import { useRegistrationGuideDialog } from "./RegistrationGuide";
import { useAfterRegistrationGuideDialog } from "./AfterRegistrationGuide";
import { afterClosedTaskGuideContentBullets, afterRegistrationGuideContentBullets, registrationGuideContentBullets, registrationTaskGuideContentBullets } from "./TutorialPage";
import { GardenerDocument, Guide } from "../gardener/gardener.model";
import { TaskResponseData } from "../task/task-response-action.hook";
import { useCallback } from "react";

const useStyles = makeStyles({
    content: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        "& > *": {
            marginTop: 0,
            marginBottom: "16px",

            "&:last-child": {
                marginBottom: 0,
            },
        },

        "& > ul": {
            marginTop: "-16px",

            "&:first-child": {
                marginTop: 0,
            },
        },

        "& > button": {
            padding: 0,
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
            textTransform: "none",
    
            "&:hover": {
                backgroundColor: "transparent",
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
});

export interface RegistrationTaskGuideListProps {
    gardener: GardenerDocument;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const RegistrationTaskGuideList = (props: RegistrationTaskGuideListProps) => {
    const classes = useStyles();

    const { gardener, taskResponseAction } = props;

    const markLookupGuideCompleted = useCallback((guide: Guide) => {
        if ( gardener.tutorial.completedGuides.includes(guide) ) return;

        taskResponseAction({ action: "mark guide completed", mode: "lookup", guide });
    }, [taskResponseAction, gardener]);

    const markRegistrationTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("registrationTask"), [markLookupGuideCompleted]);
    const startRegistrationTaskGuide = useRegistrationTaskGuideDialog(markRegistrationTaskGuideCompleted);

    const markRegistrationGuideCompleted = useCallback(() => markLookupGuideCompleted("registration"), [markLookupGuideCompleted]);
    const startRegistrationGuide = useRegistrationGuideDialog(markRegistrationGuideCompleted);

    const markAfterRegistrationGuideCompleted = useCallback(() => markLookupGuideCompleted("afterRegistration"), [markLookupGuideCompleted]);
    const startAfterRegistrationGuide = useAfterRegistrationGuideDialog("look up", markAfterRegistrationGuideCompleted);

    const markAfterClosedTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("afterClosedTask"), [markLookupGuideCompleted]);
    const startAfterCloseTaskGuide = useAfterCloseTaskGuideDialog(markAfterClosedTaskGuideCompleted);

    return (
        <AccordionList>

            <AccordionListItem id="registration-task-guide-1" title="Sådan besvarer du “Hvordan gik dit besøg?”">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {registrationTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startRegistrationTaskGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

            <AccordionListItem id="registration-task-guide-2" title="Sådan registerer du tid">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {registrationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startRegistrationGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

            <AccordionListItem id="registration-task-guide-3" title="Hvad sker der, når du registrerer tid?">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {afterRegistrationGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startAfterRegistrationGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

            <AccordionListItem id="registration-task-guide-4" title="Hvad sker der, hvis du lukker en opgave?">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {afterClosedTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startAfterCloseTaskGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

        </AccordionList>
    );
};
