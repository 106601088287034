import { Button, makeStyles } from "@material-ui/core";

import { AccordionList } from "../components/accordion/AccordionList";
import { AccordionListItem } from "../components/accordion/AccordionListItem";
import { UnorderedList } from "../components/unordered-list/UnorderedList";
import { useNewTaskGuideDialog } from "./NewTaskGuide";
import { useOnboardingGuideDialog } from "./OnboardingGuideDialog";
import { newTaskGuideContentBullets, onboardingGuideContentBullets } from "./TutorialPage";
import { useCallback } from "react";
import { GardenerDocument, Guide } from "../gardener/gardener.model";
import { TaskResponseData } from "../task/task-response-action.hook";

const useStyles = makeStyles({
    content: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        "& > *": {
            marginTop: 0,
            marginBottom: "16px",

            "&:last-child": {
                marginBottom: 0,
            },
        },

        "& > ul": {
            marginTop: "-16px",

            "&:first-child": {
                marginTop: 0,
            },
        },

        "& > button": {
            padding: 0,
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
            textTransform: "none",
    
            "&:hover": {
                backgroundColor: "transparent",
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
});

interface NewTaskGuideListProps {
    gardener: GardenerDocument;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const NewTaskGuideList = (props: NewTaskGuideListProps) => {
    const classes = useStyles();
    const { gardener, taskResponseAction } = props;

    const markLookupGuideCompleted = useCallback((guide: Guide) => {
        if ( gardener.tutorial.completedGuides.includes(guide) ) return;

        taskResponseAction({ action: "mark guide completed", mode: "lookup", guide });
    }, [taskResponseAction, gardener]);

    const markOnboardingGuideCompleted = useCallback(() => markLookupGuideCompleted("onboarding"), [markLookupGuideCompleted]);
    const startOnboardingGuide = useOnboardingGuideDialog("look up", markOnboardingGuideCompleted);

    const markNewTaskGuideCompleted = useCallback(() => markLookupGuideCompleted("newTask"), [markLookupGuideCompleted]);
    const startNewTaskGuide = useNewTaskGuideDialog(markNewTaskGuideCompleted);

    return (
        <AccordionList>

            <AccordionListItem id="new-task-guide-1" title="Generelt om konceptet">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {onboardingGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startOnboardingGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

            <AccordionListItem id="new-task-guide-2" title="Sådan besvarer du “Ny opgave”">
                <div className={classes.content}>
                    <p>Denne guide handler om:</p>
                    <UnorderedList>
                        {newTaskGuideContentBullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </UnorderedList>
                    <Button disableRipple onClick={startNewTaskGuide}>Start guide</Button>
                </div>
            </AccordionListItem>

        </AccordionList>
    );
};
