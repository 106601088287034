import { useCallback } from "react";
import { useHistory } from "react-router";

import { LinearProgress } from "../../application/LinearProgress";
import { useAllMatches } from "../../application/matches.hook";
import { useUser } from "../../user/UserProvider";
import { CurrentCustomersTab } from "./CurrentCustomersTab";

export const IntegratedCurrentCustomersTab = () => {
    const gardener = useUser();

    const status = gardener.allowRegistrationBackdoor ? undefined : "current customers";
    const matches = useAllMatches(gardener.id, status);

    const history = useHistory();
    const goToCustomer = useCallback((matchId: string) => {
        history.push(`/customers/current/${matchId}`);
    }, [history]);
    
    if ( !matches ) return <LinearProgress />;

    return (
        <CurrentCustomersTab
            matches={matches}
            goToCustomer={goToCustomer}
        />
    );
};
