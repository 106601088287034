import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, NoticeFormItem, PageFormItem, TextAreaFormItem, useFormDialog } from "../components/form-item/client/components";
import { GardenerDocument } from "./gardener.model";
import { AddressPickerFormItem } from "../components/form-item/client/form-item/AddressPickerFormItemComponent";

export enum UpdateGardenerFormIds {
    TextAreaWorkingAs = "TextAreaWorkingAs",
    TextAreaCompany = "TextAreaCompany",
    TextAreaName = "TextAreaName",
    AddressPickerAddress = "AddressPickerAddress",
    TextAreaPhoneNumber = "TextAreaPhoneNumber",
    TextAreaEmail = "TextAreaEmail",
}

export type UpdateGardenerForm = PageFormItem<
    GroupFormItem<Array<NoticeFormItem | AddressPickerFormItem | TextAreaFormItem>>
>;

export const useUpdateGardenerDialog = (gardener: GardenerDocument, onConfirm: FormProps<UpdateGardenerForm>["onSubmit"]) => {
    const form: UpdateGardenerForm = useMemo(() => {

        const items: Array<NoticeFormItem | AddressPickerFormItem | TextAreaFormItem> = [];

        items.push({
            type: "notice",
            render: "info",
            html: `Hvis du vil rette de ikke-redigerbare oplysninger, kan du skrive til os via “Bed om hjælp”.`,
        });

        items.push({
            id: UpdateGardenerFormIds.TextAreaWorkingAs,
            type: "text-area",
            render: "text-field",
            label: "Arbejder som",
            placeholder: null,
            value: !gardener.cvr ? "Privatperson (ikke momsregistreret)" : `Virksomhed (${gardener.paysVat ? "momsregistreret" : "ikke momsregistreret"})`,
            dirty: false,
            disabled: true,
        });

        if ( gardener.cvr ) {
            items.push({
                id: UpdateGardenerFormIds.TextAreaCompany,
                type: "text-area",
                render: "text-field",
                label: "Virksomhed",
                placeholder: null,
                value: `${gardener.companyName} (CVR: ${gardener.cvr})`,
                dirty: false,
                disabled: true,
            });
        }

        items.push({
            id: UpdateGardenerFormIds.TextAreaName,
            type: "text-area",
            render: "text-field",
            label: !gardener.cvr ? "Navn" : "Kontaktperson",
            placeholder: null,
            value: gardener.name,
            dirty: false,
            disabled: true,
        });

        items.push({
            id: UpdateGardenerFormIds.AddressPickerAddress,
            type: "address-picker",
            label: "Adresse",

            searchTerm: `${gardener.address.street}, ${gardener.address.zipCode}`,
            value: null,
            dirty: false,
            validation: "mandatory",
        });

        items.push({
            id: UpdateGardenerFormIds.TextAreaPhoneNumber,
            type: "text-area",
            render: "text-field",
            label: "Telefonnummer",
            placeholder: null,
            value: gardener.phoneNumber,
            dirty: false,
            validation: "phone",
        });

        items.push({
            id: UpdateGardenerFormIds.TextAreaEmail,
            type: "text-area",
            render: "text-field",
            label: "Email",
            placeholder: null,
            value: gardener.email,
            dirty: false,
            validation: "email",
        });
        
        return {
            type: "page",
            title: "Ret oplysninger",
            item: {
                type: "group",
                render: "all at once",
                items,
            },
        };
    }, [gardener]);

    return useFormDialog(form, onConfirm);
};
