import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { LinearProgress } from "../../application/LinearProgress";
import { RegistrationFollowUpTaskDocument } from "../../task/task.model";
import { ErrorPage } from "../../application/ErrorPage";
import { useTranslation } from "../../internationalisation/translation.hook";
import { RegistrationTaskDetailPage } from "./RegistrationTaskDetailPage";
import { useOpenAppointmentsForCustomer } from "../../appointment/appointments.hook";
import { TaskResponseData, useTaskResponseAction } from "../../task/task-response-action.hook";
import { useRegistrationHistory } from "../../registration/overview/registrations.hook";
import { useGardeningTask } from "../../gardening-task/gardener-view/gardening-tasks-gardener-view.hook";
import { useUser } from "../../user/UserProvider";

export interface IntegratedRegistrationTaskDetailPageProps {
    title: JSX.Element;
    task: RegistrationFollowUpTaskDocument;
}

export const IntegratedRegistrationTaskDetailPage = (props: IntegratedRegistrationTaskDetailPageProps) => {
    const { title, task } = props;
    const { t } = useTranslation();
    
    const gardener = useUser();
    const gardeningTask = useGardeningTask(task.gardenerId, task.gardeningTaskId);

    const customerAppointments = useOpenAppointmentsForCustomer(task.gardenerId, task.customerId);
    const appointment = customerAppointments && customerAppointments.find(x => x.id === task.appointmentId);

    const history = useHistory();
    const redirect = useCallback((action: TaskResponseData["action"]) => {
        if ( !customerAppointments ) return;

        switch ( action ) {
            case "update appointment": return history.push(`/overview/redirects/registration-task/${task.id}?status=performed`);
            case "cancel appointment":
                if ( customerAppointments.length > 1 ) return history.push(`/overview/redirects/registration-task/${task.id}?status=performed`);
                else return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=active`);

            case "reject task because gardener says customer does not need help": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=deleted`);;
            case "reject task because gardener says customer might need help": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=deleted`);;
            case "reject task because gardener could not help customer": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=deleted`);;
            case "create registration": return history.push(`/overview/redirects/registration-task/${task.id}?status=performed`);
        }
    }, [history, task, customerAppointments]);
    const taskResponseAction = useTaskResponseAction(redirect);

    const now = useRef(moment().toISOString(true)).current;

    const registrationHistory = useRegistrationHistory(task.gardenerId, task.customerId, now);

    if ( task.hasBeenPerformed ) return <ErrorPage message={t("OverviewTaskDetailPage: task-has-already-been-performed")} />;

    if ( gardeningTask === null || appointment === null || registrationHistory === null || customerAppointments === null ) return <ErrorPage message={t("OverviewTaskDetailPage: task-could-not-be-found")} />;
    if ( gardeningTask === undefined || appointment === undefined || registrationHistory === undefined || customerAppointments === undefined ) return <LinearProgress />;

    return (
        <>
            {title}

            <RegistrationTaskDetailPage
                gardener={gardener}
                task={task}
                gardeningTask={gardeningTask}
                appointment={appointment}
                hasHistory={registrationHistory.hasHistory}
                hasRecentHistory={registrationHistory.hasRecentHistory}
                hasMultipleAppointments={customerAppointments.length > 1}
                now={now}
                taskResponseAction={taskResponseAction}
            />
        </>
    );
};
