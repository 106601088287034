import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import moment from "moment";

import { NotFoundPage } from "../../application/NotFoundPage";
import { LinearProgress } from "../../application/LinearProgress";
import { TitleHeader } from "../../components/page/TitleHeader";
import { BackButton } from "../../components/page/BackButton";
import { useTranslation } from "../../internationalisation/translation.hook";
import { formatTitle } from "../../utility";
import { useTask } from "../../task/tasks.hook";
import { useTasks } from "../../task/TaskProvider";
import { IntegratedNewTaskDetailPage } from "../new-task/IntegratedNewTaskDetailPage";
import { ErrorPage } from "../../application/ErrorPage";
import { IntegratedRegistrationTaskDetailPage } from "../registration-task/IntegratedRegistrationTaskDetailPage";
import { IntegratedAppointmentTaskDetailPage } from "../appointment-task/IntegratedAppointmentTaskDetailPage";
import { Layout } from "../../application/Layout";
import { TaskBlockingTutorialRouter } from "../../guides/TaskBlockingTutorialRouter";

export const TaskRouter = () => {
    const { taskId, tab } = useParams<{ taskId: string, tab: "current" | "future" }>();
    const { t } = useTranslation();

    const history = useHistory();
    const goToOverview = useCallback(() => {
        history.push(`/overview/${tab}`);
    }, [history, tab]);

    const task = useTask(taskId);
    
    const now = moment().toISOString(true);
    
    const tasks = useTasks();
    const relevantTasks = tasks.filter(task => task.type === "offer-response" || moment(task.performAfterDate).isSameOrBefore(now, "day"));
    
    if ( task === undefined ) return <Layout><LinearProgress /></Layout>;
    if ( task === null ) return <Layout><NotFoundPage /></Layout>;

    const backButton = <BackButton onClick={goToOverview} />;
    const title = <TitleHeader button={backButton}>{formatTitle(t("LeftTabBar: overview-tab"), relevantTasks.length)}</TitleHeader>

    if ( task.type === "offer-response" ) {
        return (
            <TaskBlockingTutorialRouter task={task} goToOverview={goToOverview}>
                <Layout>
                    <IntegratedNewTaskDetailPage title={title} task={task} />
                </Layout>
            </TaskBlockingTutorialRouter>
        );
    }

    if ( task.type === "appointment-follow-up" ) {
        return (
            <TaskBlockingTutorialRouter task={task} goToOverview={goToOverview}>
                <Layout>
                    <IntegratedAppointmentTaskDetailPage title={title} task={task} />
                </Layout>
            </TaskBlockingTutorialRouter>
        );
    }

    if ( task.type === "registration-follow-up" ) {
        return (
            <TaskBlockingTutorialRouter task={task} goToOverview={goToOverview}>
                <Layout>
                    <IntegratedRegistrationTaskDetailPage title={title} task={task} />
                </Layout>
            </TaskBlockingTutorialRouter>
        );
    }

    return <Layout><ErrorPage message={t("OverviewTaskDetailPage: unrecognized-task-type")} /></Layout>;
};
